<app-navbar-style-two></app-navbar-style-two>
<div style="margin-top: 100px;" class="ptb-100">
    <div class="container questions-width">
        <h1 class="headline">Are we refinancing or is this investment a purchase?</h1>
        <div class="row">
            <form class="multiple-box">
                <input class="form-control haus-form-control" type="text" placeholder="State"
                    [(ngModel)]="this.hubspot.QuestionPropertyState" [ngModelOptions]="{standalone: true}" maxlength="2" required>
                <input class="form-control haus-form-control" type="text" placeholder="Estimated FICO"
                    [(ngModel)]="this.hubspot.QuestionEstimatedCreditScore" [ngModelOptions]="{standalone: true}" maxlength="3" required>
            </form>
        </div>
        <div class="row">
            <form class="multiple-box">
                <select class="form-control haus-form-control" (change)="RefinanceCheck($event)">
                    <option selected>Refinance?</option>
                    <option value="Yes">Refinance: Yes</option>
                    <option value="No">Refinance: No</option>
                </select>
                <input *ngIf="this.hubspot.QuestionRefinanceCheck == 'No'" class="form-control haus-form-control" type="text" placeholder="Purchase Price"
                    [(ngModel)]="this.hubspot.QuestionPurchasePrice" [ngModelOptions]="{standalone: true}" maxlength="7" required>
            </form>
        </div>
        <div class="row">
            <form class="multiple-box">
                <select class="form-control haus-form-control" (change)="RehabCheck($event)">
                    <option selected>Property Rehab?</option>
                    <option value="Yes">Property Rehab: Yes</option>
                    <option value="No">Property Rehab: No</option>
                </select>
                <input *ngIf="this.hubspot.QuestionRehabCheck == 'Yes'" class="form-control haus-form-control" type="text" placeholder="Estimated Cost of Rehab"
                    [(ngModel)]="this.hubspot.QuestionEstimatedRehabCost" [ngModelOptions]="{standalone: true}" maxlength="7" required>
                <input class="form-control haus-form-control" type="text" placeholder="After Repair Value (ARV)"
                    [(ngModel)]="this.hubspot.QuestionAfterRepairValue" [ngModelOptions]="{standalone: true}" maxlength="7"
                    required>
            </form>
        </div>
        <a class="haus-btn" *ngIf="this.hubspot.QuestionAfterRepairValue" (click)="this.hubspot.submit();" routerLink="/whats-next">
            <h1>Next</h1>
        </a>
        <h5 class="haus-h5" *ngIf="this.hubspot.QuestionAfterRepairValue" routerLink="/subject-property-type">Go Back</h5>
    </div>
</div>
<app-main-just-funded></app-main-just-funded>
<app-footer-style-five></app-footer-style-five>