import { Component } from '@angular/core';

@Component({
  selector: 'app-fix-and-flip-product-page',
  templateUrl: './fix-and-flip-product-page.component.html',
  styleUrls: ['./fix-and-flip-product-page.component.scss']
})
export class FixAndFlipProductPageComponent {

}
