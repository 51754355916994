import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from "@angular/common";
import { HubSpotService } from 'src/app/Services/hub-spot.service';

@Component({
  selector: 'app-question-property-address',
  templateUrl: './question-property-address.component.html',
  styleUrls: ['./question-property-address.component.scss']
})
export class QuestionPropertyAddressComponent {
  constructor(public hubspot:HubSpotService, private scroller: ViewportScroller) { }

  ngOnInit(): void {
  }
}
