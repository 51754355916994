import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-style-five',
  templateUrl: './footer-style-five.component.html',
  styleUrls: ['./footer-style-five.component.scss']
})
export class FooterStyleFiveComponent implements OnInit {
  
  public email:string = 'info@nvestorfunding.com';

  constructor() { }

  ngOnInit(): void {
  }

}
