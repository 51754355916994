<app-navbar-style-two></app-navbar-style-two>

<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Text Message Program Terms & Privacy Policy </h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Text Message Program Terms & Privacy Policy </li>
            </ul>
        </div>
    </div>
    <div class="divider"></div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
    <div class="banner-shape1"><img src="assets/img/shape/shape9.png" alt="image"></div>
</div>

<div class="privacy-policy-area ptb-100">
    <div class="container">
        <div class="privacy-policy-content">
            <h6>Program Description</h6>
            <p>
                By participating in the Nvestor Funding Inc’s SMS program, you agree to receive recurring automated
                informational text messages (e.g., SMS and MMS) from Nvestor Funding Inc to the mobile telephone number
                you provided when signing up or any other number that you designate. These messages are related to your
                loan inquiry, status and other essential information. Per Our Carrier’s Forbidden Message Categories in
                the US and Canada article, please note that first-party loan messages are acceptable if they are not
                promotional. Therefore, marketing messages will not be sent via this SMS platform. All types of messages
                you may receive shall be known collectively as the “Programs.”
            </p>
            <h5>Message Frequency</h5>
            <p>Service delivery frequency is up to 8 messages per month. Message frequency may vary depending on your
                interactions and the status of your loan. Nvestor Funding Inc reserves the right to alter the frequency
                of messages sent at any time, so as to increase or decrease the total number of sent messages. Nvestor
                Funding Inc also reserves the right to change the phone number to which messages are sent.
            </p>
            <h5>Customer Care Information</h5>
            <p>For assistance, text HELP for help or contact our customer service team at (877)231-3111. This support is
                available for any questions or issues you may have regarding the SMS service.
            </p>
            <h5>Terms of Participation</h5>
            <p>By opting in to or participating in any of the Programs, you accept and agree to these terms, including,
                without limitation, your agreement to resolve any disputes through binding, individual only arbitration
                with jury trial and class action waivers, as detailed in the “Dispute Resolution and Arbitration
                Agreement” section below.
            </p>
            <h5>Message and Data Rates</h5>
            <p>Standard message and data rates may apply. Check with your mobile service provider for details.</p>
            <h5>Opt-Out Information</h5>
            <p>If you do not wish to continue participating in any Program or no longer agree to these Terms, you agree
                to text the applicable long code phone number or directly reply to any mobile message received from a
                Program with STOP, END, CANCEL, UNSUBSCRIBE, or QUIT in order to opt out of that Program at any time.
                You may receive an additional mobile message confirming your decision to opt out. These options are the
                only reasonable and exclusive methods of opting out. Any other method of opting out, including texting
                words or phrases other than those set forth above or verbally requesting Nvestor Funding Inc to remove
                you from our list, is not a reasonable means of opting out. You may be subscribed to multiple Programs
                across different Short Codes or regular long code phone numbers, and therefore you must separately text
                or reply STOP to each Short Code or long code phone number from which you wish to unsubscribe.
            </p>
            <h5>Additional Disclosures</h5>
            <ul>
                <li>Not all mobile devices or handsets may be supported, and our messages may not be deliverable in all
                    areas. Nvestor Funding Inc, its service providers, and the mobile carriers supported by the program
                    are not liable for delayed or undelivered messages.
                </li>
                <li>Your privacy is important to us. Review our privacy policy at <a routerLink="/privacy-policy">nvestorfunding.com/privacy-policy</a> to understand how we handle your information</li>
            </ul>
        </div>
    </div>
</div>

<app-footer-style-five></app-footer-style-five>