<app-navbar-style-two></app-navbar-style-two>
<div style="margin-top: 100px;" class="ptb-100">
    <div class="container questions-width">
        <h1 class="headline">What's your phone number?</h1>
        <div class="row">
            <form class="multiple-box">
                <input class="form-control haus-form-control" type="text" placeholder="Phone Number"
                    [(ngModel)]="this.hubspot.QuestionPhoneNumber" [ngModelOptions]="{standalone: true}" maxlength="25"
                    required>
            </form>
            <p>By entering your phone number, agree to SMS messages from Nvestor Funding, Inc. See our <a
                    routerLink="/terms-of-use">Terms of Use</a> and <a routerLink="/privacy-policy">Privacy Policy</a>.
                Message frequency varies. Standard message and data rates may apply. Text STOP to opt out, reply HELP
                for help.</p>
        </div>
        <h5 class="haus-answer-h5" *ngIf="this.hubspot.QuestionPhoneNumber">Your Name: <span style="color: #F66D0E;">{{
                this.hubspot.QuestionPhoneNumber }}</span></h5>
        <a class="haus-btn" *ngIf="this.hubspot.QuestionPhoneNumber" routerLink="/whats-your-name">
            <h1>Next</h1>
        </a>
        <h5 class="haus-h5" *ngIf="this.hubspot.QuestionPhoneNumber" routerLink="/whats-your-email-address">Go Back</h5>
    </div>
</div>
<app-main-just-funded></app-main-just-funded>
<app-footer-style-five></app-footer-style-five>