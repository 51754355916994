<app-navbar-style-two></app-navbar-style-two>
<div style="margin-top: 100px;" class="ptb-100">
    <div class="container questions-width">
        <h1 class="headline">What's your exit strategy for your real estate investment?</h1>
        <div class="row">
            <div class="col">
                <a (click)="this.GetLoanType('Fix&Flip');">
                    <div class="application-questions-box">
                        <p>{{ this.Type[0].type }}</p>
                    </div>
                </a>
                <a (click)="this.GetLoanType('Rental');">
                    <div class="application-questions-box">
                        <p>{{ this.Type[1].type }}</p>
                    </div>
                </a>
                <a (click)="this.GetLoanType('Bridge');">
                    <div class="application-questions-box">
                        <p>{{ this.Type[2].type }}</p>
                    </div>
                </a>
            </div>
            <div class="col">
                <a (click)="this.GetLoanType('Construction');">
                    <div class="application-questions-box">
                        <p>{{ this.Type[3].type }}</p>
                    </div>
                </a>
                <a (click)="this.GetLoanType('Not Sure');">
                    <div class="application-questions-box">
                        <p>{{ this.Type[4].type }}</p>
                    </div>
                </a>
            </div>
        </div>
        <h5 class="haus-answer-h5" *ngIf="this.hubspot.QuestionLoanType">Exit Strategy: 
            <span style="color: #F66D0E;">{{ this.hubspot.QuestionLoanType }}</span>
        </h5>
        <a class="haus-btn" *ngIf="this.hubspot.QuestionLoanType" routerLink="/completed-fix-and-flips">
            <h1>Next</h1>
        </a>
        <h5 class="haus-h5" *ngIf="this.hubspot.QuestionLoanType" routerLink="/">Go Back</h5>
    </div>
</div>
<app-main-just-funded></app-main-just-funded>
<app-footer-style-five></app-footer-style-five>