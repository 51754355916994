<div class="new-app-download-wrap-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="new-app-download-content">
                    <span class="sub-title">QUICK PRICER</span>
                    <h2>Let's Get Your Free Copy From Apple and Play Store</h2>
                    <p>Instant free download from store Cloud based storage for your data backup just log in with your mail account from play store and using whatever you want for your business purpose orem ipsum dummy text.</p>
                    <div class="btn-box color-wrap">
                        <a routerLink="/app-download" class="default-btn">Get A Fast Free Quote</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="new-app-download-image text-end" data-aos="fade-up">
                    <img src="../../../../assets/img/products/nvestor-funding-quick-pricer.png" alt="Nvestor Funding Quick Pricer">
                    <div class="download-circle">
                        <img src="assets/img/more-home/app-download/download-circle.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="app-download-shape-1">
        <img src="assets/img/more-home/app-download/shape-1.png" alt="image">
    </div>
    <div class="app-download-shape-2">
        <img src="assets/img/more-home/app-download/shape-2.png" alt="image">
    </div>
</div>