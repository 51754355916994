<div class="app-screenshots-area bg-color pb-100">
    <div class="container">
        <div class="section-title title-with-bg-text">
            <div class="big-title">App Screen</div>
            <span class="sub-title">APP SCREENS</span>
            <h2>Beautifully Crafted All App Screenshots</h2>
        </div>
        <div class="app-screenshots-slides">
            <owl-carousel-o [options]="screenshotsSlides">
                <ng-template carouselSlide>
                    <div class="single-screenshot-card">
                        <img src="assets/img/more-home/screenshots/screenshots1.png" alt="screenshots">
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-screenshot-card">
                        <img src="assets/img/more-home/screenshots/screenshots2.png" alt="screenshots">
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-screenshot-card">
                        <img src="assets/img/more-home/screenshots/screenshots3.png" alt="screenshots">
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-screenshot-card">
                        <img src="assets/img/more-home/screenshots/screenshots4.png" alt="screenshots">
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-screenshot-card">
                        <img src="assets/img/more-home/screenshots/screenshots5.png" alt="screenshots">
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-screenshot-card">
                        <img src="assets/img/more-home/screenshots/screenshots1.png" alt="screenshots">
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
    <div class="app-screenshots-shape-1">
        <img src="assets/img/more-home/screenshots/shape-1.png" alt="image">
    </div>
    <div class="app-screenshots-shape-2">
        <img src="assets/img/more-home/screenshots/shape-2.png" alt="image">
    </div>
</div>