<div class="team-area pt-100 pb-75">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">NVESTOR FUNDING MANAGEMENT</span>
            <h2>Meet Our Team</h2>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-member">
                    <div class="image">
                        <img src="../../../../assets/img/team/nvestor-funding-ras-abdelrasul.png" alt="image">
                    </div>
                    <div class="content">
                        <h3>Ras Abdelrasul</h3>
                        <span>Chief Executive Officer</span>
                    </div>
                    <ul class="social-links">
                        <li><a href="https://www.facebook.com/nvestorfundinginc/" target="_blank"><i class="ri-facebook-fill"></i></a></li>
                        <li><a href="https://www.linkedin.com/company/nvestor-funding" target="_blank"><i class="ri-linkedin-fill"></i></a></li>
                        <li><a href="https://www.instagram.com/ras_the_baus/" target="_blank"><i class="ri-instagram-line"></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-member">
                    <div class="image">
                        <img src="../../../../assets/img/team/nvestor-funding-steve-afra.png" alt="image">
                    </div>
                    <div class="content">
                        <h3>Steve Afra</h3>
                        <span>Managing Partner</span>
                    </div>
                    <ul class="social-links">
                        <li><a href="https://www.facebook.com/nvestorfundinginc/" target="_blank"><i class="ri-facebook-fill"></i></a></li>
                        <li><a href="https://www.linkedin.com/company/nvestor-funding" target="_blank"><i class="ri-linkedin-fill"></i></a></li>
                        <li><a href="https://www.instagram.com/afrasteve/" target="_blank"><i class="ri-instagram-line"></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-member">
                    <div class="image">
                        <img src="../../../../assets/img/team/nvestor-funding-jennifer-wright.png" alt="image">
                    </div>
                    <div class="content">
                        <h3>Jennifer Wright</h3>
                        <span>Chief Revenue Officer</span>
                    </div>
                    <ul class="social-links">
                        <li><a href="https://www.facebook.com/nvestorfundinginc/" target="_blank"><i class="ri-facebook-fill"></i></a></li>
                        <li><a href="https://www.linkedin.com/company/nvestor-funding" target="_blank"><i class="ri-linkedin-fill"></i></a></li>
                        <li><a href="https://www.instagram.com/nvestorfunding/" target="_blank"><i class="ri-instagram-line"></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-member">
                    <div class="image">
                        <img src="../../../../assets/img/team/nvestor-funding-spencer-medrano.png" alt="image">
                    </div>
                    <div class="content">
                        <h3>Spencer Medrano</h3>
                        <span>Senior Vice President</span>
                    </div>
                    <ul class="social-links">
                        <li><a href="https://www.facebook.com/nvestorfundinginc/" target="_blank"><i class="ri-facebook-fill"></i></a></li>
                        <li><a href="https://www.linkedin.com/company/nvestor-funding" target="_blank"><i class="ri-linkedin-fill"></i></a></li>
                        <li><a href="https://www.instagram.com/nvestorfunding/" target="_blank"><i class="ri-instagram-line"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>