import { Component } from '@angular/core';

@Component({
  selector: 'app-main-fix-and-flip-product',
  templateUrl: './main-fix-and-flip-product.component.html',
  styleUrls: ['./main-fix-and-flip-product.component.scss']
})
export class MainFixAndFlipProductComponent {

}
