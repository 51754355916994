import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from "@angular/common";
import { HubSpotService } from 'src/app/Services/hub-spot.service';

@Component({
  selector: 'app-question-flip-experience',
  templateUrl: './question-flip-experience.component.html',
  styleUrls: ['./question-flip-experience.component.scss']
})
export class QuestionFlipExperienceComponent {

  public Experience:any = [
    {
        experience: '0 Completed Flips',
        value: '0 Completed Flips'
    },
    {
        experience: '1 Completed Flips',
        value: '1 Completed Flips'
    },
    {
        experience: '2 Completed Flips',
        value: '2 Completed Flips'
    },
    {
      experience: '3 Completed Flips',
      value: '3 Completed Flips'
    },
    {
      experience: '4 Completed Flips',
      value: '4 Completed Flips'
    },
    {
      experience: '5 Completed Flips',
      value: '5 Completed Flips'
    },
    {
      experience: '5+ Completed Flips',
      value: '5+ Completed Flips'
    }
  ];

  constructor(public hubspot:HubSpotService, private scroller: ViewportScroller) { }

  ngOnInit(): void {
  }

  public GetFlipExperience(value :any) {
    this.hubspot.QuestionFlipExperience = value;
    console.log('Fix and Flip Experience:', this.hubspot.QuestionFlipExperience);
  }

}
