import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from "@angular/common";
import { HubSpotService } from 'src/app/Services/hub-spot.service';

@Component({
  selector: 'app-question-subject-property-type',
  templateUrl: './question-subject-property-type.component.html',
  styleUrls: ['./question-subject-property-type.component.scss']
})
export class QuestionSubjectPropertyTypeComponent {
  public Type:any = [
    {
        type:  'Single-Family Detached',
        value: 'Single-Family Detached'
    },
    {
        type:  'Condo',
        value: 'Condo'
    },
    {
        type:  'Townhome',
        value: 'Townhome'
    },
    {
      type:  '2-4 Unit',
      value: '2-4 Unit'
    },
    {
      type:  'Multifamily(5+)',
      value: 'Multifamily(5+)'
    },
    {
      type:  'Other',
      value: 'Other'
    }
  ];

  constructor(public hubspot:HubSpotService, private scroller: ViewportScroller) { }

  ngOnInit(): void {
  }

  public GetPropertyType(value :any) {
    this.hubspot.QuestionPropertyType = value;
    console.log('Property Type:', this.hubspot.QuestionPropertyType);
  }

}
