import { Component } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-hard-money-loans-georgia-page',
  templateUrl: './hard-money-loans-georgia-page.component.html',
  styleUrls: ['./hard-money-loans-georgia-page.component.scss']
})
export class HardMoneyLoansGeorgiaPageComponent {
  constructor(public meta: Meta) {
    this.meta.addTag({
      name: 'description',
      content: `Unleash Georgia's Real Estate Potential with Nvestor Funding: 
                Your Hard Money Loan Powerhouse! Fast closings, flexible terms, & fix-and-flip firepower 
                - conquer Georgia's market with Nvestor Funding's expert hard money loans`
    });
  }
}
