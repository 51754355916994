import { Component } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-ground-up-construction-product-page',
  templateUrl: './ground-up-construction-product-page.component.html',
  styleUrls: ['./ground-up-construction-product-page.component.scss']
})
export class GroundUpConstructionProductPageComponent {
  constructor(public meta: Meta) {
    this.meta.addTag({
      name: 'description',
      content: `Get competitive rates and flexible terms on ground up 
                construction loans from Nvestor Funding. We finance a 
                variety of property types and loan amounts.`
    });
  }
}
