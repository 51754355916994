<div class="pb-100 pt-100 how-it-works-area">
    <div class="container">
        <div class="section-title">
            <h2>It's As Easy As, 1, 2, 3</h2>
        </div>
        <div class="how-it-works-content">
            <div class="number">1</div>
            <div class="row m-0">
                <div class="col-lg-3 col-md-12 p-0">
                    <div class="box">
                        <h3>Step 1</h3>
                        <span>Prequal Questions</span>
                    </div>
                </div>
                <div class="col-lg-9 col-md-12 p-0">
                    <div class="content">
                        <p>Unlike normal loan applications, we only need basic information such as your experience as a real estate investor, your estimated FICO, and the property of the location.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="how-it-works-content">
            <div class="number">2</div>
            <div class="row m-0">
                <div class="col-lg-3 col-md-12 p-0">
                    <div class="box">
                        <h3>Step 2</h3>
                        <span>Docs & Appraisal</span>
                    </div>
                </div>
                <div class="col-lg-9 col-md-12 p-0">
                    <div class="content">
                        <p>Just like the prequal questions, we only need the basic documents in order to move forward with your loan, such as your entity docs, evidence of insurance or mortage as we run your appraisal simultaneously.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="how-it-works-content">
            <div class="number">3</div>
            <div class="row m-0">
                <div class="col-lg-3 col-md-12 p-0">
                    <div class="box">
                        <h3>Step 3</h3>
                        <span>Underwriting</span>
                    </div>
                </div>
                <div class="col-lg-9 col-md-12 p-0">
                    <div class="content">
                        <p>If all is well, we dispurse funds and introduce you to your post closing specialist for questions and draws (if it's a rehab loan).</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>