<div class="app-progress-area bg-black ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="app-progress-animation-image">
                    <img src="assets/img/app-progress/progress-main.png" alt="app-progress">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="app-progress-content text-white">
                    <span class="sub-title">APP PROGRESS</span>
                    <h2>Set Up The Challenges and Track Your Progress</h2>
                    <p>Cloud based storage for your data backup just log in with your mail account from play store and using whatever you want for your business purpose orem ipsum dummy text. never missyour chance its just began. backup just log in with your mail account from.</p>
                    <p>Most provabily best  for your data backup just log in with your mail account from play store and using whatever you want for your business purpose orem ipsum dummy  backup just log in with your mail account from.</p>
                    <a routerLink="/pricing" class="default-btn">Start Free Trial</a>
                </div>
            </div>
        </div>
    </div>
</div>