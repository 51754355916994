import { Component } from '@angular/core';

@Component({
  selector: 'app-july-top-ten-zips',
  templateUrl: './july-top-ten-zips.component.html',
  styleUrls: ['./july-top-ten-zips.component.scss']
})
export class JulyTopTenZipsComponent {

}
