import { Component, OnInit } from '@angular/core';
import { HubSpotService } from 'src/app/Services/hub-spot.service';

@Component({
  selector: 'app-question-subject-property-cost',
  templateUrl: './question-subject-property-cost.component.html',
  styleUrls: ['./question-subject-property-cost.component.scss']
})
export class QuestionSubjectPropertyCostComponent {

  constructor(public hubspot:HubSpotService) { }

  ngOnInit(): void {
  }

  RefinanceCheck(event: any) {
    this.hubspot.QuestionRefinanceCheck = event.target.value;
  }

  RehabCheck(event: any) {
    this.hubspot.QuestionRehabCheck = event.target.value;
  }
}
