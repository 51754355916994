<app-navbar-style-two></app-navbar-style-two>
<div style="margin-top: 100px;" class="ptb-100">
    <div class="container questions-width">
        <h1 class="headline">How many rental properties do you currently own?</h1>
        <div class="row">
            <div class="col">
                <a (click)="this.GetRentalExperience('0');">
                    <div class="application-questions-box">
                        <p>{{ this.Experience[0].experience }}</p>
                    </div>
                </a>
                <a (click)="this.GetRentalExperience('1');">
                    <div class="application-questions-box">
                        <p>{{ this.Experience[1].experience }}</p>
                    </div>
                </a>
                <a (click)="this.GetRentalExperience('2');">
                    <div class="application-questions-box">
                        <p>{{ this.Experience[2].experience }}</p>
                    </div>
                </a>
            </div>
            <div class="col">
                <a (click)="this.GetRentalExperience('3');">
                    <div class="application-questions-box">
                        <p>{{ this.Experience[3].experience }}</p>
                    </div>
                </a>
                <a (click)="this.GetRentalExperience('4');">
                    <div class="application-questions-box">
                        <p>{{ this.Experience[4].experience }}</p>
                    </div>
                </a>
                <a (click)="this.GetRentalExperience('5');">
                    <div class="application-questions-box">
                        <p>{{ this.Experience[5].experience }}</p>
                    </div>
                </a>
                <a (click)="this.GetRentalExperience('6');">
                    <div class="application-questions-box">
                        <p>{{ this.Experience[6].experience }}</p>
                    </div>
                </a>
            </div>
        </div>
        <h5 class="haus-answer-h5" *ngIf="this.hubspot.QuestionRentalExperience">Total Owned Rental Properties: <span
                style="color: #F66D0E;">{{ this.hubspot.QuestionRentalExperience }}</span></h5>
        <a class="haus-btn" *ngIf="this.hubspot.QuestionRentalExperience" routerLink="/estimated-fico-score">
            <h1>Next</h1>
        </a>
        <h5 class="haus-h5" *ngIf="this.hubspot.QuestionRentalExperience" routerLink="/completed-fix-and-flips">Go Back
        </h5>
    </div>
</div>
<app-main-just-funded></app-main-just-funded>
<app-footer-style-five></app-footer-style-five>