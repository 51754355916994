import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from "@angular/common";
import { HubSpotService } from 'src/app/Services/hub-spot.service';

@Component({
  selector: 'app-question-rental-experience',
  templateUrl: './question-rental-experience.component.html',
  styleUrls: ['./question-rental-experience.component.scss']
})
export class QuestionRentalExperienceComponent {

  public Experience: any = [
    {
      experience: '0 Owned Rentals',
      value: '0 Owned Rentals'
    },
    {
      experience: '1 Owned Rental',
      value: '1 Owned Rental'
    },
    {
      experience: '2 Owned Rentals',
      value: '2 Owned Rentals'
    },
    {
      experience: '3 Owned Rentals',
      value: '3 Owned Rentals'
    },
    {
      experience: '4 Owned Rentals',
      value: '4 Owned Rentals'
    },
    {
      experience: '5 Owned Rentals',
      value: '5 Owned Rentals'
    },
    {
      experience: '5+ Owned Rentals',
      value: '5+ Owned Rentals'
    }
  ];

  constructor(public hubspot: HubSpotService, private scroller: ViewportScroller) { }

  ngOnInit(): void {
  }

  public GetRentalExperience(value: any) {
    this.hubspot.QuestionRentalExperience = value;
    console.log('Rental Experience:', this.hubspot.QuestionRentalExperience);
  }
}
