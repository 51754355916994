<aside class="widget-area">
    <div class="widget widget_apzel_posts_thumb">
        <h3 class="widget-title">Most Recent Posts</h3>
        <article class="item">
            <a routerLink="/texas-hard-money-loans-a-faster-path-to-real-estate-success-for-investors-and-flippers"
                class="thumb"><span class="fullimage cover bg1" role="img"></span></a>
            <div class="info">
                <h4 class="title usmall"><a routerLink="/texas-hard-money-loans-a-faster-path-to-real-estate-success-for-investors-and-flippers">Texas Hard Money Loans: A Faster Path to Real
                        Estate Success for Investors and Flippers</a></h4>
                <span class="date"><i class="ri-calendar-2-fill"></i> December 16, 2023</span>
            </div>
        </article>
        <article class="item">
            <a routerLink="/investing-in-florida-real-estate-discover-the-benefits-of-hard-money-loanssave"
                class="thumb"><span class="fullimage cover bg2" role="img"></span></a>
            <div class="info">
                <h4 class="title usmall"><a routerLink="/investing-in-florida-real-estate-discover-the-benefits-of-hard-money-loanssave">Investing in Florida Real Estate? Discover the
                        Benefits of Hard Money Loans</a></h4>
                <span class="date"><i class="ri-calendar-2-fill"></i> December 15, 2023</span>
            </div>
        </article>
        <article class="item">
            <a routerLink="/unlocking-opportunities-a-guide-to-buying-an-investment-property-with-minimal-down-payment" class="thumb"><span class="fullimage cover bg3" role="img"></span></a>
            <div class="info">
                <h4 class="title usmall"><a routerLink="/unlocking-opportunities-a-guide-to-buying-an-investment-property-with-minimal-down-payment">Unlocking Opportunities: A Guide to Buying an
                        Investment Property with Minimal Down Payment</a></h4>
                <span class="date"><i class="ri-calendar-2-fill"></i> December 14, 2023</span>
            </div>
        </article>
        <article class="item">
            <a routerLink="/the-ultimate-guide-to-ground-up-construction-loans-everything-you-need-to-know" class="thumb"><span class="fullimage cover bg4" role="img"></span></a>
            <div class="info">
                <h4 class="title usmall"><a routerLink="/the-ultimate-guide-to-ground-up-construction-loans-everything-you-need-to-know">The Ultimate Guide to Ground-Up Construction Loans: Everything You Need to Know</a></h4>
                <span class="date"><i class="ri-calendar-2-fill"></i> December 13, 2023</span>
            </div>
        </article>
        <article class="item">
            <a routerLink="/student-housing-the-next-big-thing-in-cre" class="thumb"><span class="fullimage cover bg5" role="img"></span></a>
            <div class="info">
                <h4 class="title usmall"><a routerLink="/student-housing-the-next-big-thing-in-cre">Student Housing: The Next Big Thing in CRE</a></h4>
                <span class="date"><i class="ri-calendar-2-fill"></i>July 14, 2023</span>
            </div>
        </article>
        <article class="item">
            <a routerLink="/are-you-next-the-10-zip-codes-where-foreclosures-are-on-the-rise" class="thumb"><span class="fullimage cover bg6" role="img"></span></a>
            <div class="info">
                <h4 class="title usmall"><a routerLink="/are-you-next-the-10-zip-codes-where-foreclosures-are-on-the-rise">Are You Next? The 10 ZIP Codes Where Foreclosures Are on the Rise</a></h4>
                <span class="date"><i class="ri-calendar-2-fill"></i>July 7, 2023</span>
            </div>
        </article>
    </div>
</aside>