import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HubSpotService {
  public QuestionLoanType: any;
  public QuestionFlipExperience: any;
  public QuestionRentalExperience: any;
  public QuestionPropertyLocation: any;
  public QuestionPropertyStreetAddress: any;
  public QuestionPropertyCity: any;
  public QuestionPropertyState: any;
  public QuestionPropertyZip: any;
  public QuestionEstimatedCreditScore: any;
  public QuestionPurchasePrice: any;
  public QuestionEstimatedRehabCost: any;
  public QuestionAfterRepairValue: any;
  public QuestionPropertyType: any;
  public QuestionRefinanceCheck: any;
  public QuestionRehabCheck: any;
  public QuestionFirstName: any;
  public QuestionLastName: any;
  public QuestionEmailAddress: any;
  public QuestionPhoneNumber: any;
  public data: any;

  public ContactPageFirstName: any;
  public ContactPageLastName: any;
  public ContactPageEmailAddress: any;
  public ContactPhoneNumber: any;
  public cdata: any;

  public _url = 'https://nvestorfunding.com/submit'
  public _curl = 'https://nvestorfunding.com/contact'

  constructor(public http: HttpClient) { }

  public submit() {
    const headers = new HttpHeaders()
      .set('Authorization', 'my-auth-token')
      .set('Content-Type', 'application/json');

    this.data = {
      loan_type: this.QuestionLoanType,
      of_flips_in_last_36_months: this.QuestionFlipExperience,
      of_rental_properties_owned: this.QuestionRentalExperience,
      email: this.QuestionEmailAddress,
      phone: this.QuestionPhoneNumber,
      firstname: this.QuestionFirstName,
      lastname: this.QuestionLastName,
      subject_property_address: this.QuestionPropertyStreetAddress,
      subject_property_city: this.QuestionPropertyCity,
      subject_property_state: this.QuestionPropertyState,
      subject_property_zip_code: this.QuestionPropertyZip,
      subject_property_type: this.QuestionPropertyType,
      estimated_fico_score: this.QuestionEstimatedCreditScore,
      requested_loan_amount: this.QuestionPurchasePrice,
      rehab_budget: this.QuestionEstimatedRehabCost,
      estimated_as_repaired_value: this.QuestionAfterRepairValue,
      refinance_: this.QuestionRefinanceCheck,
    }
    console.log('Data:', this.data);

    this.http.post(this._url, JSON.stringify(this.data), {
      headers: headers
      }).subscribe(data => {
        data = this.data;
        console.log('Ng Log:', data);
      });
  };

  public contact() {
    const headers = new HttpHeaders()
      .set('Authorization', 'my-auth-token')
      .set('Content-Type', 'application/json');

    this.data = {
      firstname: this.ContactPageFirstName,
      lastname: this.ContactPageLastName,
      email: this.ContactPageEmailAddress,
      phone: this.ContactPhoneNumber,
    }
    console.log('Data:', this.data);
    this.http.post(this._curl, JSON.stringify(this.cdata), {
      headers: headers
      }).subscribe(data => {
        data = this.cdata;
        console.log('Ng Log:', data);
      });
  };

}
