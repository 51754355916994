<div class="new-app-main-banner-wrap-area">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="new-app-main-banner-wrap-content">
                    <span style="margin-top: 100px;" class="sub-title">Fix and Flip, Long Term Rental, Short Term Bridge, Ground Up Construction</span>
                    <h1>Fast and Reliable Private Money</h1>
                    <p>Nvestor Funding is more than a lender, we’re your partner. With local market knowledge and a streamlined process,
                        we work with you through the life of your real estate investment loan to ensure that your
                        project gets completed quickly and efficiently.</p>
                    <a routerLink="/exit-strategy" class="default-btn mt-5">Get A Free Fast Quote</a>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="new-app-main-banner-wrap-image">
                    <img src="assets/img/more-home/banner/nvestor-funding-private-lender-banner-3.png"
                        alt="Nvestor Funding Pivate Lender">
                </div>
            </div>
        </div>
    </div>
    <div class="new-app-main-banner-wrap-shape">
        <img src="assets/img/more-home/banner/shape-5.png" alt="image">
    </div>
</div>