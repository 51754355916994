<div class="faq-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <div class="faq-sidebar">
                    <ul>
                        <li><a routerLink="/hard-money-loans-arizona"><span>Hard Money Loans Arizona</span></a></li>
                        <li><a routerLink="/hard-money-loans-california"><span>Hard Money Loans California</span></a></li>
                        <li><a routerLink="/hard-money-loans-florida"><span>Hard Money Loans Florida</span></a></li>
                        <li><a routerLink="/hard-money-loans-georgia"><span>Hard Money Loans Georgia</span></a></li>
                        <li><a routerLink="/hard-money-loans-los-angeles"><span>Hard Money Loans Los Angeles</span></a></li>
                        <li><a routerLink="/hard-money-loans-nashville"><span>Hard Money Loans Nashville</span></a></li>
                        <li><a routerLink="/hard-money-loans-north-carolina"><span>Hard Money Loans North Carolina</span></a></li>
                        <li><a routerLink="/hard-money-loans-tennessee"><span>Hard Money Loans Tennessee</span></a></li>
                        <li><a routerLink="/hard-money-loans-texas"><span>Hard Money Loans Texas</span></a></li>
                        <li><a routerLink="/hard-money-loans-san-diego"><span>Hard Money Loans San Diego</span></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-8 col-md-12">
                <div class="faq-accordion accordion">
                    <div class="accordion">
                        <div class="accordion-section">
                            <div class="accordion-header" (click)="toggleSection(0)"
                                [ngClass]="{ 'open': isSectionOpen(0) }">
                                What is a fix and flip loan?
                            </div>
                            <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(0) }">
                                <p>
                                    A fix and flip loan is a type of short-term financing used by real estate investors
                                    to purchase and renovate a property before selling it for a profit. Unlike
                                    traditional loans, fix and flip loans are designed for quick turnaround and are
                                    often used to cover both the purchase price and the renovation costs. At Nvestor
                                    Funding, we specialize in providing robust fix and flip loans to help turn your
                                    investment into a profitable venture swiftly and efficiently.
                                </p>
                            </div>
                        </div>
                        <div class="accordion-section">
                            <div class="accordion-header" (click)="toggleSection(1)"
                                [ngClass]="{ 'open': isSectionOpen(1) }">
                                How do I apply for a fix and flip loan?
                            </div>
                            <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(1) }">
                                <p>
                                    Applying for a fix and flip loan with Nvestor Funding is a straightforward process
                                    aimed at getting your project moving without delay. Start by reaching out to our
                                    team with details about the property and your renovation plans. You'll need to
                                    provide relevant documentation, including your purchase agreement, renovation
                                    budget, and personal financial information. Our experts are here to guide you
                                    through each step, ensuring a smooth and timely application process.
                                </p>
                            </div>
                        </div>
                        <div class="accordion-section">
                            <div class="accordion-header" (click)="toggleSection(2)"
                                [ngClass]="{ 'open': isSectionOpen(2) }">
                                What are the requirements for a fix and flip loan?
                            </div>
                            <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(2) }">
                                <p>
                                    To qualify for a fix and flip loan from Nvestor Funding, investors typically need to
                                    present a detailed plan for the property, including the purchase price and estimated
                                    renovation costs. A good credit score, experience in real estate flipping, and a
                                    solid financial standing can also play crucial roles in the approval process. We
                                    assess each application carefully to ensure our loan products align with your
                                    project's needs and your financial health.
                                </p>
                            </div>
                        </div>
                        <div class="accordion-section">
                            <div class="accordion-header" (click)="toggleSection(3)"
                                [ngClass]="{ 'open': isSectionOpen(3) }">
                                What states do you lend fix and flip loans in?
                            </div>
                            <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(3) }">
                                <p>
                                    Nvestor Funding offers fix and flip loans in most states aside from AK, UT, ND, SD,
                                    NV, and VT.
                                    Our wide coverage ensures that we understand the unique market
                                    conditions and opportunities in various regions, providing you with tailored advice
                                    and financial solutions. Check if your project's state is on our list and get in
                                    touch to discuss how we can help finance your next flip.
                                </p>
                            </div>
                        </div>
                        <div class="accordion-section">
                            <div class="accordion-header" (click)="toggleSection(4)"
                                [ngClass]="{ 'open': isSectionOpen(4) }">
                                Is there a draw process for a fix and flip loan?
                            </div>
                            <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(4) }">
                                <p>
                                    Similar to our ground-up construction loans, our fix and flip loans may involve a
                                    draw process, allowing you to access funds in stages as your renovation progresses.
                                    This ensures that the funds are available when you need them for each phase of your
                                    project, from purchase to the final touches before sale. Our team will work with you
                                    to establish a draw schedule that aligns with your project timeline and budget.
                                </p>
                            </div>
                        </div>
                        <div class="accordion-section">
                            <div class="accordion-header" (click)="toggleSection(5)"
                                [ngClass]="{ 'open': isSectionOpen(5) }">
                                How do your fix and flip loans work?
                            </div>
                            <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(5) }">
                                <p>
                                    Our fix and flip loans are designed to provide you with the capital needed to
                                    quickly purchase and renovate properties. Once approved, you'll receive the funds
                                    necessary to move forward with your purchase and begin renovations. The loan
                                    typically covers a portion of the purchase price and the renovation costs, with
                                    interest charged on the amount disbursed. We partner with you throughout the
                                    process, ensuring the loan serves your project's needs and timeline.
                                </p>
                            </div>
                        </div>
                        <div class="accordion-section">
                            <div class="accordion-header" (click)="toggleSection(6)"
                                [ngClass]="{ 'open': isSectionOpen(6) }">
                                What are your minimum and maximum loan amounts for a fix and flip loan?
                            </div>
                            <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(6) }">
                                <p>
                                    At Nvestor Funding, we offer a variety of loan amounts to suit different scales of
                                    fix and flip projects. Our minimum and maximum loan amounts are $200,000 to
                                    $3,500,000,
                                    accommodating a wide range of investment sizes. Whether you're
                                    working on a modest renovation or a major overhaul, we have the financial solutions
                                    to support your goals. Discuss your project with us to find the perfect loan amount
                                    for your needs.
                                </p>
                            </div>
                        </div>
                        <div class="accordion-section">
                            <div class="accordion-header" (click)="toggleSection(7)"
                                [ngClass]="{ 'open': isSectionOpen(7) }">
                                Can I close a fix and flip loan in an LLC?
                            </div>
                            <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(7) }">
                                <p>
                                    Yes, investors can close their fix and flip loans through an LLC with Nvestor
                                    Funding. This approach can offer additional legal protections and potential tax
                                    advantages for your investment. We understand the nuances of financing through an
                                    LLC and can guide you through the process, ensuring you make informed decisions that
                                    align with your investment strategy and legal considerations.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>