import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from "@angular/common";
import { HubSpotService } from 'src/app/Services/hub-spot.service';

@Component({
  selector: 'app-question-email',
  templateUrl: './question-email.component.html',
  styleUrls: ['./question-email.component.scss']
})
export class QuestionEmailComponent {
  constructor(public hubspot:HubSpotService, private scroller: ViewportScroller) { }

  ngOnInit(): void {
  }
}
