import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-terms-conditions-page',
  templateUrl: './terms-conditions-page.component.html',
  styleUrls: ['./terms-conditions-page.component.scss']
})
export class TermsConditionsPageComponent implements OnInit {
  public email: any = "info@nvestorfunding.com";
  constructor() { }

  ngOnInit(): void {
  }

}
