<div class="faq-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <div class="faq-sidebar">
                    <ul>
                        <li><a routerLink="/hard-money-loans-arizona"><span>Hard Money Loans Arizona</span></a></li>
                        <li><a routerLink="/hard-money-loans-california"><span>Hard Money Loans California</span></a></li>
                        <li><a routerLink="/hard-money-loans-florida"><span>Hard Money Loans Florida</span></a></li>
                        <li><a routerLink="/hard-money-loans-georgia"><span>Hard Money Loans Georgia</span></a></li>
                        <li><a routerLink="/hard-money-loans-los-angeles"><span>Hard Money Loans Los Angeles</span></a></li>
                        <li><a routerLink="/hard-money-loans-nashville"><span>Hard Money Loans Nashville</span></a></li>
                        <li><a routerLink="/hard-money-loans-north-carolina"><span>Hard Money Loans North Carolina</span></a></li>
                        <li><a routerLink="/hard-money-loans-tennessee"><span>Hard Money Loans Tennessee</span></a></li>
                        <li><a routerLink="/hard-money-loans-texas"><span>Hard Money Loans Texas</span></a></li>
                        <li><a routerLink="/hard-money-loans-san-diego"><span>Hard Money Loans San Diego</span></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-8 col-md-12">
                <div class="faq-accordion accordion">
                    <div class="accordion">
                        <div class="accordion-section">
                            <div class="accordion-header" (click)="toggleSection(0)"
                                [ngClass]="{ 'open': isSectionOpen(0) }">
                                What is a ground-up construction loan?
                            </div>
                            <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(0) }">
                                <p>Ground-up construction loans are specialized financing solutions designed to fund the
                                    building of new real estate projects from scratch. Unlike traditional loans that are
                                    used to purchase existing properties, ground-up construction loans cover the cost of
                                    developing a new property, from laying the foundation to the final touches. At
                                    Nvestor Funding, we recognize the unique needs of real estate investors and offer
                                    tailored ground-up construction loans to turn your vision into a tangible asset.</p>
                            </div>
                        </div>
                        <div class="accordion-section">
                            <div class="accordion-header" (click)="toggleSection(1)"
                                [ngClass]="{ 'open': isSectionOpen(1) }">
                                How do I apply for a ground-up construction loan?
                            </div>
                            <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(1) }">
                                <p>Applying for a ground-up construction loan with Nvestor Funding is a streamlined
                                    process designed to get your project off the ground quickly. Start by contacting our
                                    team to discuss your project details and financial needs. You'll then be guided
                                    through providing necessary documentation, such as your construction plan and
                                    budget, personal financial information, and details about the property. Our experts
                                    are here to help you at every step, ensuring a smooth and efficient application
                                    process.
                                </p>
                            </div>
                        </div>
                        <div class="accordion-section">
                            <div class="accordion-header" (click)="toggleSection(2)"
                                [ngClass]="{ 'open': isSectionOpen(2) }">
                                What are the requirements for a ground-up construction loan?
                            </div>
                            <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(2) }">
                                <p>To qualify for a ground-up construction loan from Nvestor Funding, you'll need to
                                    meet certain criteria. These typically include a detailed project plan, a realistic
                                    budget, a solid credit score, and proof of a successful track record in real estate
                                    investment. Additionally, we assess the projected value of the completed project and
                                    your financial stability to ensure the loan is a fit for both parties. Understanding
                                    these requirements upfront can help streamline your application process.
                                </p>
                            </div>
                        </div>
                        <div class="accordion-section">
                            <div class="accordion-header" (click)="toggleSection(3)"
                                [ngClass]="{ 'open': isSectionOpen(3) }">
                                What states do you lend ground-up construction loans in?
                            </div>
                            <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(3) }">
                                <p>Nvestor Funding is proud to support real estate investors across various states.
                                    Our extensive reach means we understand diverse market dynamics and can provide
                                    tailored
                                    advice and support, no matter where your project is located. If your state is
                                    listed,
                                    reach out to us to discuss how we can help finance your next construction project.
                                </p>
                            </div>
                        </div>
                        <div class="accordion-section">
                            <div class="accordion-header" (click)="toggleSection(4)"
                                [ngClass]="{ 'open': isSectionOpen(4) }">
                                Is there a draw process for a ground-up construction loan?
                            </div>
                            <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(4) }">
                                <p>Yes, ground-up construction loans from Nvestor Funding typically involve a draw
                                    process. This means the loan amount is not disbursed in one lump sum but rather
                                    released in stages as the construction progresses. Each draw corresponds to a
                                    completed phase of the project, ensuring funds are used efficiently and effectively.
                                    Our team works closely with you to outline and agree upon the draw schedule, keeping
                                    your project on track and within budget.
                                </p>
                            </div>
                        </div>
                        <div class="accordion-section">
                            <div class="accordion-header" (click)="toggleSection(5)"
                                [ngClass]="{ 'open': isSectionOpen(5) }">
                                How do your ground-up construction loans work?
                            </div>
                            <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(5) }">
                                <p>Ground-up construction loans are designed to support your project from start to
                                    finish. Once you're approved and the terms are set, the loan works through a draw
                                    process, releasing funds as specific milestones are achieved. Interest is typically
                                    charged only on the drawn amount, not the entire loan, helping to manage costs
                                    during construction. We work closely with you to monitor progress and ensure the
                                    project moves forward as planned.
                                </p>
                            </div>
                        </div>
                        <div class="accordion-section">
                            <div class="accordion-header" (click)="toggleSection(6)"
                                [ngClass]="{ 'open': isSectionOpen(6) }">
                                What are your minimum and maximum loan amounts for a ground-up construction loan?
                            </div>
                            <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(6) }">
                                <p>At Nvestor Funding, we understand that each construction project is unique. That's
                                    why we offer a range of loan amounts to suit various needs and scales of projects.
                                    Our minimum and maximum loan amounts are $250,000 to $3,500,000, designed to
                                    accommodate everything from small-scale builds to large, ambitious developments.
                                    Speak with our team to discuss your project and find a loan amount that aligns with
                                    your financial needs and goals.
                                </p>
                            </div>
                        </div>
                        <div class="accordion-section">
                            <div class="accordion-header" (click)="toggleSection(7)"
                                [ngClass]="{ 'open': isSectionOpen(7) }">
                                Can I close a ground-up construction loan in an LLC?
                            </div>
                            <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(7) }">
                                <p>Yes, at Nvestor Funding, we allow borrowers to close their ground-up construction
                                    loans through an LLC. This can provide additional legal protection and potential tax
                                    benefits for your investment. However, it's important to understand the specific
                                    requirements and implications of this approach. Our team can guide you through the
                                    process, ensuring you make the best decision for your financial and investment
                                    strategy.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>