<div class="key-features-area bg-transparent-with-color pt-100 pb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">WHY US?</span>
            <h2>3 Reasons Why You Should Work With Us</h2>
        </div>
        <div class="row justify-content-center">
            <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                <div class="key-features-card style-two">
                    <div class="icon">
                        <i class="ri-eye-line"></i>
                    </div>
                    <h3>Flexible & Tailored Experience</h3>
                    <p>Just log in with your mail account from play store and using whatever you want for your able business purpose.</p>
                </div>
            </div>
            <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                <div class="key-features-card bg-color-two style-two">
                    <div class="icon bg2">
                        <i class="ri-stack-line"></i>
                    </div>
                    <h3>We Have No Prepayment Penalties</h3>
                    <p>Just log in with your mail account from play store and using whatever you want for your able business purpose.</p>
                </div>
            </div>
            <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                <div class="key-features-card style-two">
                    <div class="icon">
                        <i class="ri-leaf-line"></i>
                    </div>
                    <h3>Fast Closings With In-House Underwriting</h3>
                    <p>Just log in with your mail account from play store and using whatever you want for your able business purpose.</p>
                </div>
            </div>
        </div>
        <div class="key-features-btn">
            <a routerLink="/app-download" class="default-btn">Get A Fast Free Quote</a>
        </div>
    </div>
</div>