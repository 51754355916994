<app-navbar-style-two></app-navbar-style-two>
<div style="margin-top: 100px;" class="ptb-100">
    <div class="container questions-width">
        <h1 class="headline">What type of property is your investment property?</h1>
        <div class="row">
            <div class="col">
            <a (click)="this.GetPropertyType('Single-Family Detached');">
                <div class="application-questions-box">
                    <p>{{ this.Type[0].type }}</p>
                </div>
            </a>
            <a (click)="this.GetPropertyType('Condo');">
                <div class="application-questions-box">
                    <p>{{ this.Type[1].type }}</p>
                </div>
            </a>
            <a (click)="this.GetPropertyType('Townhome');">
                <div class="application-questions-box">
                    <p>{{ this.Type[2].type }}</p>
                </div>
            </a>
            <a (click)="this.GetPropertyType('2-4 Unit');">
                <div class="application-questions-box">
                    <p>{{ this.Type[3].type }}</p>
                </div>
            </a>
            </div>
            <div class="col">
                <a (click)="this.GetPropertyType('Multifamily(5+)');">
                    <div class="application-questions-box">
                        <p>{{ this.Type[4].type }}</p>
                    </div>
                </a>
                <a (click)="this.GetPropertyType('Other');">
                    <div class="application-questions-box">
                        <p>{{ this.Type[5].type }}</p>
                    </div>
                </a>
            </div>
        </div>
        <h5 class="haus-answer-h5" *ngIf="this.hubspot.QuestionPropertyType">Property Type: 
            <span style="color: #F66D0E;">{{ this.hubspot.QuestionPropertyType }}</span>
        </h5>
        <a class="haus-btn" *ngIf="this.hubspot.QuestionPropertyType" routerLink="/are-we-refinancing">
            <h1>Next</h1>
        </a>
        <h5 class="haus-h5" *ngIf="this.hubspot.QuestionPropertyType" routerLink="/subject-property-address">Go Back</h5>
    </div>
</div>
<app-main-just-funded></app-main-just-funded>
<app-footer-style-five></app-footer-style-five>