<app-navbar-style-two></app-navbar-style-two>

<div class="page-title-area page-title-style-two">
    <div class="container">
        <div class="page-title-content">
            <h2>Contact Us</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Contact Us</li>
            </ul>
        </div>
    </div>
    <div class="divider"></div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
    <div class="banner-shape1"><img src="assets/img/shape/shape9.png" alt="image"></div>
</div>

<div class="contact-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Connect With Us</h2>
            <p>Servicing hundreds of clients at any time of the day, don't hesitate to ask.</p>
        </div>
        <div class="contact-form">
            <form id="contactForm">
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="form-group">
                            <input type="text" name="firstname" [(ngModel)]="this.hubspot.ContactPageFirstName" class="form-control" placeholder="First Name">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="form-group">
                            <input type="text" name="lastname" [(ngModel)]="this.hubspot.ContactPageLastName" class="form-control" placeholder="Last Name">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="form-group">
                            <input type="email" name="email" [(ngModel)]="this.hubspot.ContactPageEmailAddress" class="form-control" placeholder="Email Address">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="form-group">
                            <input type="text" name="phone" [(ngModel)]="this.hubspot.ContactPhoneNumber" class="form-control" placeholder="Phone Number">
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <button (click)="this.hubspot.contact();" type="submit" class="default-btn"><i class='bx bx-paper-plane'></i> Submit</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="maps">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3331.7107205529273!2d-111.94352532439034!3d33.37861755299912!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x872b06034dfd22dd%3A0x2124dfa36a8f26f4!2s40%20W%20Baseline%20Rd%2C%20Tempe%2C%20AZ%2085283!5e0!3m2!1sen!2sus!4v1703742038156!5m2!1sen!2sus" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
</div>

<app-contact-info></app-contact-info>
<app-footer-style-five></app-footer-style-five>