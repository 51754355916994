<div class="footer-wrap-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo">
                        <img src="../../../../assets/img/logo/nvestor-funding-logo.png" alt="Nvestor Funding Logo">
                    </a>
                    <p>40 W. Baseline Rd, #206, Tempe, AZ 85283</p>
                    <p>{{this.email}}</p>
                    <p>(877) 231-3111</p>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget pl-2">
                    <h3>Company</h3>
                    <ul class="links-list">
                        <li><a routerLink="/contact">Contact Us</a></li>
                        <li><a routerLink="/about-us">About Us</a></li>
                        <li><a routerLink="/">Terms & Conditions</a></li>
                        <li><a routerLink="/blog">Blog</a></li>
                        <li><a href="https://nvestorfunding.com/sitemap.xml">Site Map</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Loan Products</h3>
                    <ul class="links-list">
                        <li><a routerLink="/fix-and-flip-loans">Fix and Flip</a></li>
                        <li><a routerLink="/long-term-rental-loans">Long Term Rental</a></li>
                        <li><a routerLink="/short-term-bridge-loans">Short Term Bridge</a></li>
                        <li><a routerLink="/ground-up-construction-loans">Ground Up Construction</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Follow Us</h3>
                    <ul class="social-links">
                        <li><a href="https://www.facebook.com/nvestorfundinginc/" target="_blank"><i class="ri-facebook-fill"></i></a></li>
                        <li><a href="https://www.instagram.com/nvestorfunding/" target="_blank"><i class="ri-instagram-fill"></i></a></li>
                        <li><a href="https://www.linkedin.com/company/nvestor-funding" target="_blank"><i class="ri-linkedin-fill"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="copyright-area">
            <p>© Nvestor Funding inc. 2025 makes commercial, business purpose loans. Loans are for investment purposes
                only and not for personal, family, or household use. Loan product availability may be limited in certain
                states. This is not a commitment to lend. All loans are subject to borrower underwriting and credit
                approval, in Nvestor Funding's sole and absolute discretion. Other restrictions apply. NMLS ID #
                1910850; Loans made or arranged in California are made pursuant to a California Finance Lender License
                60DBO-105133; Arizona Mortgage Banker License # 1004737; Oregon Mortgage Lending License #ML-5895. This
                email and any files transmitted with it are confidential and intended solely for the use of the
                individual or entity to whom they are addressed. This message contains confidential information and is
                intended only for the individual named. If you are not the named addressee you should not disseminate,
                distribute or copy this e-mail. Please notify the sender immediately by e-mail if you have received this
                e-mail by mistake and delete this e-mail from your system. If you are not the intended recipient you are
                notified that disclosing, copying, distributing, or taking any action in reliance on the contents of
                this information is strictly prohibited.</p>
        </div>
    </div>
</div>