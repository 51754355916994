import { Component } from '@angular/core';

@Component({
  selector: 'app-december-texas-hard-money-loans',
  templateUrl: './december-texas-hard-money-loans.component.html',
  styleUrls: ['./december-texas-hard-money-loans.component.scss']
})
export class DecemberTexasHardMoneyLoansComponent {

}
