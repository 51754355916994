<div class="banner-area">
    <div class="container-fluid">
        <div class="row align-items-center m-0">
            <div class="col-lg-6 col-md-12 p-0">
                <div class="banner-content">
                    <span class="sub-title">Get your 14 days free trial</span>
                    <h1>The Revolutionary App That Makes Your Life Easier Than Others</h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id tincidunt eifend odio viverra diam aliquet donec again.</p>
                    <a routerLink="/pricing" class="default-btn">Start Free Trial</a>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 p-0">
                <div class="banner-image">
                    <img src="assets/img/banner/banner-app2.png" alt="image">
                </div>
            </div>
        </div>
    </div>
    <div class="banner-shape3"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape13"><img src="assets/img/shape/shape15.png" alt="shape"></div>
    <div class="banner-shape6"><img src="assets/img/shape/shape16.png" alt="image"></div>
    <div class="banner-shape7"><img src="assets/img/shape/shape11.png" alt="image"></div>
    <div class="banner-shape8"><img src="assets/img/shape/shape11.png" alt="image"></div>
</div>