<app-navbar-style-two></app-navbar-style-two>
<div style="margin-top: 100px;" class="ptb-100">
    <div class="container questions-width">
        <h1 class="headline">Thanks {{ this.hubspot.QuestionFirstName }}, an email with next steps will land in your inbox shortly.</h1>
        <h5>If you have any immediate questions, our team is on standby waiting for your call, 
            <span style="color:#F66D0E;">
            <a style="color:#F66D0E;" href="tel:18772313111">contact us here.</a>
            </span>
        </h5>
    </div>
</div>
<app-main-just-funded></app-main-just-funded>
<app-footer-style-five></app-footer-style-five>