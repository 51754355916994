<app-navbar-style-two></app-navbar-style-two>
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Blog</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Blog</li>
            </ul>
        </div>
    </div>
    <div class="divider"></div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
    <div class="banner-shape1"><img src="assets/img/shape/shape9.png" alt="image"></div>
</div>

<div class="blog-area ptb-100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="image">
                        <a routerLink="/texas-hard-money-loans-a-faster-path-to-real-estate-success-for-investors-and-flippers"
                            class="d-block">
                            <img src="../../../../../assets/img/blog/Texas-Hard-Money-Loans-A-Faster-Path-to-Real-Estate-Success-for-Investors-and-Flippers.png"
                                alt="texas-hard-money-loans-a-faster-path-to-real-estate-success-for-investors-and-flippers">
                        </a>
                        <a routerLink="/" class="tag">Nvestor Funding</a>
                    </div>
                    <div class="content">
                        <ul class="meta">
                            <li><i class="ri-time-line"></i>December 16, 2023</li>
                        </ul>
                        <h3>
                            <a
                                routerLink="/texas-hard-money-loans-a-faster-path-to-real-estate-success-for-investors-and-flippers">
                                Texas Hard Money Loans: A Faster Path to Real Estate Success for Investors and Flippers
                            </a>
                        </h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="image">
                        <a routerLink="/investing-in-florida-real-estate-discover-the-benefits-of-hard-money-loanssave"
                            class="d-block">
                            <img src="../../../../../assets/img/blog/Investing-in-Florida-Real-Estate-Discover-the-Benefits-of-Hard-Money-Loans.png"
                                alt="blog">
                        </a>
                        <a routerLink="/" class="tag">Nvestor Funding</a>
                    </div>
                    <div class="content">
                        <ul class="meta">
                            <li><i class="ri-time-line"></i>December 15, 2023</li>
                        </ul>
                        <h3>
                            <a
                                routerLink="/investing-in-florida-real-estate-discover-the-benefits-of-hard-money-loanssave">
                                Investing in Florida Real Estate? Discover the Benefits of Hard Money Loans
                            </a>
                        </h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="image">
                        <a routerLink="/unlocking-opportunities-a-guide-to-buying-an-investment-property-with-minimal-down-payment"
                            class="d-block">
                            <img src="../../../../../assets/img/blog/Unlocking-Opportunities-A-Guide-to-Buying-an-Investment-Property-with-Minimal-Down-Payment.png"
                                alt="Unlocking Opportunities a Guide to Buying an Investment Property With Minimal Down Payment">
                        </a>
                        <a routerLink="/" class="tag">Nvestor Funding</a>
                    </div>
                    <div class="content">
                        <ul class="meta">
                            <li><i class="ri-time-line"></i> December 14, 2023</li>
                        </ul>
                        <h3>
                            <a
                                routerLink="/unlocking-opportunities-a-guide-to-buying-an-investment-property-with-minimal-down-payment">
                                Unlocking Opportunities: A Guide to Buying an Investment Property with Minimal Down
                                Payment
                            </a>
                        </h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="image">
                        <a routerLink="/the-ultimate-guide-to-ground-up-construction-loans-everything-you-need-to-know"
                            class="d-block">
                            <img src="../../../../../assets/img/blog/The-Ultimate-Guide-to-Ground-Up-Construction-Loans-Everything-You-Need-to-Know.jpg"
                                alt="The Ultimate Guide to Ground Up Construction Loans Everything You Need to Know">
                        </a>
                        <a routerLink="/" class="tag">Nvestor Funding</a>
                    </div>
                    <div class="content">
                        <ul class="meta">
                            <li><i class="ri-time-line"></i>December 13, 2023</li>
                        </ul>
                        <h3><a
                                routerLink="/the-ultimate-guide-to-ground-up-construction-loans-everything-you-need-to-know">The
                                Ultimate Guide to Ground-Up Construction Loans: Everything You Need to Know</a></h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="image">
                        <a routerLink="/student-housing-the-next-big-thing-in-cre" class="d-block">
                            <img src="../../../../../assets/img/blog/Student-Housing-The-Next-Big-Thing-in-CRE.jpg"
                                alt="Student Housing: The Next Big Thing in CRE">
                        </a>
                        <a routerLink="/" class="tag">Nvestor Funding</a>
                    </div>
                    <div class="content">
                        <ul class="meta">
                            <li><i class="ri-time-line"></i>July 14, 2023</li>
                        </ul>
                        <h3><a routerLink="/student-housing-the-next-big-thing-in-cre">Student Housing: The Next Big Thing in CRE</a></h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="image">
                        <a routerLink="/are-you-next-the-10-zip-codes-where-foreclosures-are-on-the-rise"
                            class="d-block">
                            <img src="../../../../../assets/img/blog/Are-You-Next-The-10-ZIP-Codes-Where-Foreclosures-Are-on-the-Rise.jpg"
                                alt="Are You Next? The 10 ZIP Codes Where Foreclosures Are on the Rise">
                        </a>
                        <a routerLink="/" class="tag">Nvestor Funding</a>
                    </div>
                    <div class="content">
                        <ul class="meta">
                            <li><i class="ri-time-line"></i>July 7, 2023</li>
                        </ul>
                        <h3><a routerLink="/are-you-next-the-10-zip-codes-where-foreclosures-are-on-the-rise">
                                Are You Next? The 10 ZIP Codes Where Foreclosures Are on the Rise
                            </a></h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <div class="pagination-area">
                    <div class="nav-links">
                        <span class="page-numbers current">1</span>
                        <!-- <a routerLink="/blog" class="page-numbers">2</a>
                        <a routerLink="/blog" class="page-numbers">3</a>
                        <a routerLink="/blog" class="next page-numbers" title="Next Page"><i
                                class="ri-arrow-right-line"></i></a> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer-style-five></app-footer-style-five>