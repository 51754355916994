import { Component } from '@angular/core';

@Component({
  selector: 'app-july-next-big-thing',
  templateUrl: './july-next-big-thing.component.html',
  styleUrls: ['./july-next-big-thing.component.scss']
})
export class JulyNextBigThingComponent {

}
