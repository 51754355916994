<div class="contact-info-area pb-100">
    <div class="container">
        <div class="contact-info-inner">
            <h2>Have any question in mind please call or mail us</h2>
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-contact-info-box">
                        <div class="icon">
                            <i class="ri-earth-line"></i>
                        </div>
                        <h3><a href="mailto:info@nvestorfunding.com">info@nvestorfunding.com</a></h3>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-contact-info-box">
                        <div class="icon bg2">
                            <i class="ri-map-pin-line"></i>
                        </div>
                        <h3>40 W. Baseline Road #206, Tempe, AZ 85283</h3>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-contact-info-box">
                        <div class="icon bg1">
                            <i class="ri-customer-service-2-line"></i>
                        </div>
                        <h3><a href="tel:18772313111">(877) 231-3111</a></h3>
                    </div>
                </div>
            </div>
            <div class="lines">
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
            </div>
        </div>
    </div>
</div>