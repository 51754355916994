import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-main-just-funded',
  templateUrl: './main-just-funded.component.html',
  styleUrls: ['./main-just-funded.component.scss']
})
export class MainJustFundedComponent {

  constructor() { }

  ngOnInit(): void { }

  screenshotsSlides: OwlOptions = {
    nav: false,
    loop: true,
    margin: 25,
    dots: true,
    autoplay: true,
    autoplayHoverPause: true,
    navText: [
      "<i class='ri-arrow-left-s-line'></i>",
      "<i class='ri-arrow-right-s-line'></i>",
    ],
    responsive: {
      0: {
        items: 1
      },
      576: {
        items: 2
      },
      768: {
        items: 3
      },
      992: {
        items: 4
      },
      1200: {
        items: 5
      }
    }
  }

}
