import { Component } from '@angular/core';

@Component({
  selector: 'app-main-how-it-works',
  templateUrl: './main-how-it-works.component.html',
  styleUrls: ['./main-how-it-works.component.scss']
})
export class MainHowItWorksComponent {

}
