import { Component } from '@angular/core';

@Component({
  selector: 'app-ground-up-construction-faq',
  templateUrl: './ground-up-construction-faq.component.html',
  styleUrls: ['./ground-up-construction-faq.component.scss']
})
export class GroundUpConstructionFAQComponent {
  constructor() { }

  ngOnInit(): void {}

  // Accordion
  contentHeight: number = 0;
  openSectionIndex: number = -1;
  toggleSection(index: number): void {
      if (this.openSectionIndex === index) {
          this.openSectionIndex = -1;
      } else {
          this.openSectionIndex = index;
          this.calculateContentHeight();
      }
  }
  isSectionOpen(index: number): boolean {
      return this.openSectionIndex === index;
  }
  calculateContentHeight(): void {
      const contentElement = document.querySelector('.accordion-content');
      if (contentElement) {
          this.contentHeight = contentElement.scrollHeight;
      }
  }
}
