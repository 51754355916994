import { Component } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-short-term-bridge-product-page',
  templateUrl: './short-term-bridge-product-page.component.html',
  styleUrls: ['./short-term-bridge-product-page.component.scss']
})
export class ShortTermBridgeProductPageComponent {
  constructor(public meta: Meta) {
    this.meta.addTag({
      name: 'description',
      content: `Get competitive rates and flexible terms on short term bridge loans 
                from Nvestor Funding. We finance a variety of property types and loan amounts.`
    });
  }
}
