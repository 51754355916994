<app-navbar-style-two></app-navbar-style-two>
<div style="margin-top: 100px;" class="ptb-100">
    <div class="container questions-width">
        <h1 class="headline">How many properties have you flipped (or exited) in the last 36 months?</h1>
        <div class="row">
            <div class="col">
                <a (click)="this.GetFlipExperience('0');">
                    <div class="application-questions-box">
                        <p>{{ this.Experience[0].experience }}</p>
                    </div>
                </a>
                <a (click)="this.GetFlipExperience('1');">
                    <div class="application-questions-box">
                        <p>{{ this.Experience[1].experience }}</p>
                    </div>
                </a>
                <a (click)="this.GetFlipExperience('2');">
                    <div class="application-questions-box">
                        <p>{{ this.Experience[2].experience }}</p>
                    </div>
                </a>
                </div>
                <div class="col">
                    <a (click)="this.GetFlipExperience('3');">
                        <div class="application-questions-box">
                            <p>{{ this.Experience[3].experience }}</p>
                        </div>
                    </a>
                    <a (click)="this.GetFlipExperience('4');">
                        <div class="application-questions-box">
                            <p>{{ this.Experience[4].experience }}</p>
                        </div>
                    </a>
                    <a (click)="this.GetFlipExperience('5');">
                        <div class="application-questions-box">
                            <p>{{ this.Experience[5].experience }}</p>
                        </div>
                    </a>
                    <a (click)="this.GetFlipExperience('6');">
                        <div class="application-questions-box">
                            <p>{{ this.Experience[6].experience }}</p>
                        </div>
                    </a>
                </div>
        </div>
        <h5 class="haus-answer-h5" *ngIf="this.hubspot.QuestionFlipExperience">Total Completed Fix & Flips: <span style="color: #F66D0E;">{{ this.hubspot.QuestionFlipExperience }}</span></h5>
        <a class="haus-btn" *ngIf="this.hubspot.QuestionFlipExperience" routerLink="/owned-long-term-rentals">
            <h1>Next</h1>
        </a>
        <h5 class="haus-h5" *ngIf="this.hubspot.QuestionFlipExperience" routerLink="/exit-strategy">Go Back</h5>
    </div>
</div>
<app-main-just-funded></app-main-just-funded>
<app-footer-style-five></app-footer-style-five>