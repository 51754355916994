import { Component } from '@angular/core';

@Component({
  selector: 'app-december-ground-up-construction',
  templateUrl: './december-ground-up-construction.component.html',
  styleUrls: ['./december-ground-up-construction.component.scss']
})
export class DecemberGroundUpConstructionComponent {

}
