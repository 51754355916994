import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from "@angular/common";
import { HubSpotService } from 'src/app/Services/hub-spot.service';

@Component({
  selector: 'app-question-estimated-fico',
  templateUrl: './question-estimated-fico.component.html',
  styleUrls: ['./question-estimated-fico.component.scss']
})
export class QuestionEstimatedFICOComponent {

  public Score:any = [
    {
      score: '< 600',
      value: '< 600'
    },
    {
      score: '601-640',
      value: '601-640'
    },
    {
      score: '641-680',
      value: '641-680'
    },
    {
      score: '681-720',
      value: '681-720'     
    },
    {
      score: '721-760',
      value: '721-760'     
    },
    {
      score: '761-800',
      value: '761-800'     
    },
    {
      score: '> 800',
      value: '> 800'     
    }
  ];

  constructor(public hubspot:HubSpotService, private scroller: ViewportScroller) { }

  ngOnInit(): void {
  }

  public GetEstimatedScore(value :any) {
    this.hubspot.QuestionEstimatedCreditScore = value;
    console.log('Estimated Credit Score:', this.hubspot.QuestionEstimatedCreditScore);
  }

}
