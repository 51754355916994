<div class="app-ever-area pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="app-ever-content with-left-zero">
                    <h2>Most Probably You Are Getting Best App Ever</h2>
                    <p>Cloud based storage for your data backup just log in with your mail account from play store and using whatever you want for your business purpose orem ipsum dummy text. Never missyour chance its just began.</p>
                    <ul class="list">
                        <li>
                            <div class="icon bg3 rounded-circle">
                                <i class="ri-download-cloud-2-line"></i>
                            </div>
                            <h3>Free Download App</h3>
                            <p>Most provabily best you can trust on it, just log in with your mail account from play store and using whatever you want for your business.</p>
                        </li>
                        <li>
                            <div class="icon rounded-circle">
                                <i class="ri-award-line"></i>
                            </div>
                            <h3>Trusted and Reliable</h3>
                            <p>Most provabily best you can trust on it, just log in with your mail account from play store and using whatever you want for your business.</p>
                        </li>
                        <li>
                            <div class="icon bg3 rounded-circle">
                                <i class="ri-cloud-line"></i>
                            </div>
                            <h3>Cloud Storage</h3>
                            <p>Just log in with your mail account from play store and using whatever you want for your business purpose.</p>
                        </li>
                    </ul>
                    <div class="btn-box">
                        <a routerLink="/app-download" class="default-btn">Start Free Trial</a>
                        <a routerLink="/features-1" class="link-btn">See All Features</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="app-ever-wrap-image">
                    <img src="assets/img/more-home/app-ever/app-ever-2.png" alt="image">
                    <div class="wrap-shape">
                        <img src="assets/img/more-home/app-ever/shape-2.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="features-area pb-75">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-3 col-lg-4 col-sm-6 col-md-6">
                <div class="features-card style-two">
                    <div class="icon">
                        <i class="ri-smartphone-line"></i>
                        <div class="number">1</div>
                    </div>
                    <h3>User Friendly</h3>
                    <p>Most Provably best dashboard design for your business you can.</p>
                </div>
            </div>
            <div class="col-xl-3 col-lg-4 col-sm-6 col-md-6">
                <div class="features-card style-two">
                    <div class="icon">
                        <i class="ri-award-line"></i>
                        <div class="number">2</div>
                    </div>
                    <h3>Award-Winning App</h3>
                    <p>Most Provably best dashboard design for your business you can.</p>
                </div>
            </div>
            <div class="col-xl-3 col-lg-4 col-sm-6 col-md-6">
                <div class="features-card style-two">
                    <div class="icon">
                        <i class="ri-fingerprint-line"></i>
                        <div class="number">3</div>
                    </div>
                    <h3>Privacy Protected</h3>
                    <p>Most Provably best dashboard design for your business you can.</p>
                </div>
            </div>
            <div class="col-xl-3 col-lg-4 col-sm-6 col-md-6">
                <div class="features-card style-two">
                    <div class="icon">
                        <i class="ri-vip-diamond-line"></i>
                        <div class="number">4</div>
                    </div>
                    <h3>Lifetime Update</h3>
                    <p>Most Provably best dashboard design for your business you can.</p>
                </div>
            </div>
        </div>
    </div>
</div>