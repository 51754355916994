import { Component } from '@angular/core';

@Component({
  selector: 'app-main-long-term-rental-product',
  templateUrl: './main-long-term-rental-product.component.html',
  styleUrls: ['./main-long-term-rental-product.component.scss']
})
export class MainLongTermRentalProductComponent {

}
