import { Component } from '@angular/core';

@Component({
  selector: 'app-main-ground-up-construction-product',
  templateUrl: './main-ground-up-construction-product.component.html',
  styleUrls: ['./main-ground-up-construction-product.component.scss']
})
export class MainGroundUpConstructionProductComponent {

}
