<div class="gradient-funfacts-area pt-100 pb-75">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-funfacts-card">
                    <div class="icon">
                        <i class="ri-global-line"></i>
                    </div>
                    <p>Lending Nationwide</p>
                    <h3><span class="odometer" [countUp]="46">00</span><span class="sign"> States</span></h3>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-funfacts-card">
                    <div class="icon">
                        <i class="ri-download-2-line"></i>
                    </div>
                    <p>Loans Funded</p>
                    <h3><span class="odometer" [countUp]="500">00</span><span class="sign"> Million</span></h3>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-funfacts-card">
                    <div class="icon">
                        <i class="ri-star-fill"></i>
                    </div>
                    <p>Years Experience</p>
                    <h3><span class="odometer" [countUp]="28">00</span><span class="sign"> Years</span></h3>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-funfacts-card">
                    <div class="icon">
                        <i class="ri-map-pin-user-line"></i>
                    </div>
                    <p>Clients Served</p>
                    <h3><span class="odometer" [countUp]="480">00</span><span class="sign"> Served</span></h3>
                </div>
            </div>
        </div>
    </div>
</div>