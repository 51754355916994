<app-navbar-style-two></app-navbar-style-two>

<div class="page-title-area page-title-style-two">
    <div class="container">
        <div class="page-title-content">
            <h2>About Us</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>About Us</li>
            </ul>
        </div>
    </div>
    <div class="divider"></div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
    <div class="banner-shape1"><img src="assets/img/shape/shape9.png" alt="image"></div>
</div>

<div class="about-area pt-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title">ABOUT US</span>
                    <h2>Your Premier Partner in Real Estate Investment Financing.</h2>
                    <p>With 20 years of dedicated experience, Nvestor Funding has established itself as a leading
                        private money lending company, committed to empowering real estate investors. Our focus is on
                        providing capital to visionary individuals looking to fix and flip properties, secure rental
                        opportunities, or embark on ground-up construction projects. We understand the unique challenges
                        and opportunities within metropolitan markets across the USA, and we're here to help you
                        capitalize on them.</p>
                    <div class="features-text">
                        <h2>Why Us?</h2>
                        <h6>Two Decades of Expertise:</h6>
                        <p>We bring 20 years of experience to the table, ensuring that every transaction is handled with
                            professionalism and an in-depth understanding of real estate investment dynamics.
                        </p>
                    </div>
                    <div class="features-text">
                        <h6>Thousands of Loans Funded:</h6>
                        <p>Our track record speaks for itself. We've successfully funded thousands of loans, helping
                            hundreds of clients turn their real estate aspirations into tangible assets.</p>
                    </div>
                    <div class="features-text">
                        <h6>Tailored Solutions Nationwide:</h6>
                        <p>Our focus on metropolitan cities across the USA means we're well-versed in the nuances of
                            each market we serve, providing you with insights and financing solutions that fit.</p>
                    </div>
                    <div class="features-text">
                        <h6>Cutting-Edge Technology:</h6>
                        <p>We leverage the latest technology to streamline the lending process, ensuring you can focus
                            more on your investment and less on the paperwork.</p>
                    </div>
                    <div class="features-text">
                        <h6>Fast Closings:</h6>
                        <p>Time is money, especially in real estate. Our efficient processes ensure quick closings, so
                            you can move forward with your project without unnecessary delays.</p>
                    </div>
                    <div class="features-text">
                        <h6>Competitive Capital:</h6>
                        <p>We understand the importance of cost-effective financing. Our competitive rates ensure that
                            you're getting the quality service you deserve at a price that makes sense for your bottom
                            line.</p>
                    </div>
                    <div class="btn-box">
                        <a routerLink="/exit-strategy" class="default-btn">Get A Free Fast Quote</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-img">
                    <img src="../../../../assets/img/team/nvestor-funding-team.png" data-aos="fade-up" alt="Nvestor Funding Team">
                </div>
            </div>
        </div>
    </div>
</div>

<app-progress-style-one></app-progress-style-one>

<app-team-style-two></app-team-style-two>

<div class="partner-area">
    <div class="ptb-100 container">
        <div class="partner-title">
            As Seen On:
        </div>
        <app-partner></app-partner>
    </div>
</div>

<app-footer-style-five></app-footer-style-five>