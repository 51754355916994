<div class="feedback-area bg-F4F8FC ptb-100">
    <div class="container">
        <div class="feedback-tab-slides">
            <owl-carousel-o [options]="feedbackTabSlides">
                <ng-template carouselSlide>
                    <div class="feedback-box">
                        <p>Nvestor funding is the REAL DEAL! They provide cutting edge products accompanied by even better
                            service. If you are a mortgage broker looking to find a place for your business purpose loan,
                            you MUST give them a try. The entire team is very knowledgeable and they know how to get deals
                            done quickly.</p>
                        <h6><span>Troy S.</span> - Mortgage Broker</h6>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="feedback-box">
                        <p>I worked with Troy and the Nvestor Funding team on a previous rental property of mine. They were
                            extremely helpful throughout the whole process and were able to get my loan closed very fast. I
                            plan to use them on another upcoming rental I plan to pick up in the next few months.</p>
                        <h6><span>Bruce P.</span> - Real Estate Investor</h6>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="feedback-box">
                        <p>The most painless loan process I have been through. In fact I would say it was pleasant and fast
                            moving. The whole team was very responsive and communicated so so well. They've got my paper
                            from here on out. Thanks Dana, Nick and the rest of the team behind the scenes!</p>
                        <h6><span>Bruce P.</span> - Real Estate Developer</h6>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</div>