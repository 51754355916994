import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacy-policy-page',
  templateUrl: './privacy-policy-page.component.html',
  styleUrls: ['./privacy-policy-page.component.scss']
})
export class PrivacyPolicyPageComponent implements OnInit {
  public email:any = 'info@nvestorfunding.com';
  constructor() { }

  ngOnInit(): void {
  }

}
