import { Component } from '@angular/core';

@Component({
  selector: 'app-main-short-term-bridge-product',
  templateUrl: './main-short-term-bridge-product.component.html',
  styleUrls: ['./main-short-term-bridge-product.component.scss']
})
export class MainShortTermBridgeProductComponent {

}
