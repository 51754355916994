import { Component } from '@angular/core';

@Component({
  selector: 'app-main-technology-why-us',
  templateUrl: './main-technology-why-us.component.html',
  styleUrls: ['./main-technology-why-us.component.scss']
})
export class MainTechnologyWhyUsComponent {

}
