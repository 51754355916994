import { Component, OnInit } from '@angular/core';
import { HubSpotService } from 'src/app/Services/hub-spot.service';

@Component({
    selector: 'app-contact-page',
    templateUrl: './contact-page.component.html',
    styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent implements OnInit {

    constructor(public hubspot: HubSpotService) { }

    ngOnInit(): void {
    }

}