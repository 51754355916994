import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-trusted-features',
  templateUrl: './trusted-features.component.html',
  styleUrls: ['./trusted-features.component.scss']
})
export class TrustedFeaturesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
