import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from "@angular/common";
import { HubSpotService } from 'src/app/Services/hub-spot.service';

@Component({
  selector: 'app-question-name',
  templateUrl: './question-name.component.html',
  styleUrls: ['./question-name.component.scss']
})
export class QuestionNameComponent {

  constructor(public hubspot:HubSpotService, private scroller: ViewportScroller) { }

  ngOnInit(): void {
  }

}
