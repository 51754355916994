import { Component } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-hard-money-loans-texas-page',
  templateUrl: './hard-money-loans-texas-page.component.html',
  styleUrls: ['./hard-money-loans-texas-page.component.scss']
})
export class HardMoneyLoansTexasPageComponent {
  constructor(public meta: Meta) {
    this.meta.addTag({
      name: 'description',
      content: `Nvestor Funding provides flexible and fast hard money loans 
                for real estate investors in Texas. Close quickly and access 
                the capital you need. Contact us today!`
    });
  }
}