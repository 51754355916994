<app-navbar-style-two></app-navbar-style-two>
<div style="margin-top: 100px;" class="ptb-100">
    <div class="container questions-width">
        <h1 class="headline">What's your estimated credit score?</h1>
        <div class="row">
            <div class="col">
            <a (click)="this.GetEstimatedScore('< 600');">
                <div class="application-questions-box">
                    <p>{{ this.Score[0].score }}</p>
                </div>
            </a>
            <a (click)="this.GetEstimatedScore('601-640');">
                <div class="application-questions-box">
                    <p>{{ this.Score[1].score }}</p>
                </div>
            </a>
            <a (click)="this.GetEstimatedScore('641-680');">
                <div class="application-questions-box">
                    <p>{{ this.Score[2].score }}</p>
                </div>
            </a>
            <a (click)="this.GetEstimatedScore('681-720');">
                <div class="application-questions-box">
                    <p>{{ this.Score[3].score }}</p>
                </div>
            </a>
            </div>
            <div class="col">
                <a (click)="this.GetEstimatedScore('721-760');">
                    <div class="application-questions-box">
                        <p>{{ this.Score[4].score }}</p>
                    </div>
                </a>
                <a (click)="this.GetEstimatedScore('761-800');">
                    <div class="application-questions-box">
                        <p>{{ this.Score[5].score }}</p>
                    </div>
                </a>
                <a (click)="this.GetEstimatedScore('> 800');">
                    <div class="application-questions-box">
                        <p>{{ this.Score[6].score }}</p>
                    </div>
                </a>
            </div>
        </div>
        <h5 class="haus-answer-h5" *ngIf="this.hubspot.QuestionEstimatedCreditScore">Estimated FICO: 
            <span style="color: #F66D0E;">{{ this.hubspot.QuestionEstimatedCreditScore }}</span>
        </h5>
        <a class="haus-btn" *ngIf="this.hubspot.QuestionEstimatedCreditScore" routerLink="/whats-your-email-address">
            <h1>Next</h1>
        </a>
        <h5 class="haus-h5" *ngIf="this.hubspot.QuestionEstimatedCreditScore" routerLink="/owned-long-term-rentals">Go Back</h5>
    </div>
</div>
<app-main-just-funded></app-main-just-funded>
<app-footer-style-five></app-footer-style-five>