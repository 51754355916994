import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-text-message-program-terms',
  templateUrl: './text-message-program-terms.component.html',
  styleUrls: ['./text-message-program-terms.component.scss']
})
export class TextMessageProgramTermsComponent implements OnInit {
  public email: any = "info@nvestorfunding.com";
  constructor() { }

  ngOnInit(): void {
  }
}
