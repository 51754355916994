import { Component } from '@angular/core';

@Component({
  selector: 'app-main-you-tube-video',
  templateUrl: './main-you-tube-video.component.html',
  styleUrls: ['./main-you-tube-video.component.scss']
})
export class MainYouTubeVideoComponent {

}
