<app-navbar-style-one></app-navbar-style-one>

<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Gallery</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Gallery</li>
            </ul>
        </div>
    </div>
    <div class="divider"></div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
    <div class="banner-shape1"><img src="assets/img/shape/shape9.png" alt="image"></div>
</div>

<div class="gallery-area pt-100 pb-75">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-gallery-item">
                    <img src="assets/img/gallery/gallery1.jpg" alt="gallery-image" />
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-gallery-item">
                    <img src="assets/img/gallery/gallery2.jpg" alt="gallery-image" />
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-gallery-item">
                    <img src="assets/img/gallery/gallery3.jpg" alt="gallery-image" />
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-gallery-item">
                    <img src="assets/img/gallery/gallery4.jpg" alt="gallery-image" />
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-gallery-item">
                    <img src="assets/img/gallery/gallery5.jpg" alt="gallery-image" />
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-gallery-item">
                    <img src="assets/img/gallery/gallery6.jpg" alt="gallery-image" />
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-gallery-item">
                    <img src="assets/img/gallery/gallery7.jpg" alt="gallery-image" />
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-gallery-item">
                    <img src="assets/img/gallery/gallery8.jpg" alt="gallery-image" />
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-gallery-item">
                    <img src="assets/img/gallery/gallery9.jpg" alt="gallery-image" />
                </div>
            </div>
        </div>
    </div>
</div>

<div class="app-download-area pb-100">
    <app-download-app-style-two></app-download-app-style-two>
</div>

<app-footer-style-one></app-footer-style-one>