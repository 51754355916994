<!-- Free Trial -->
<div class="new-free-trial-area">
    <div class="container">
        <div class="new-free-trial-inner-box">
            <div class="row align-items-center">
                <div class="col-lg-9 col-md-9">
                    <div class="new-free-trial-content">
                        <span class="sub-title">FREE TRIAL</span>
                        <h2>Start Your 14 Days Free Trials Today!</h2>
                        <form class="free-trial-form">
                            <input type="text" class="input-newsletter" placeholder="Enter Your Email Address" name="email">
                            <button type="submit" class="default-btn">Get Started</button>
                        </form>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3">
                    <div class="new-free-trial-image">
                        <img src="assets/img/more-home/free-trial/free-trial.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="new-free-trial-shape">
                <img src="assets/img/more-home/free-trial/shape.png" alt="image">
            </div>
        </div>
    </div>
</div>

<!-- Footer -->
<div class="footer-area-style-with-black-color">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo">
                        <img src="assets/img/logo.png" alt="logo">
                    </a>
                    <p>Best solution for your it startup business, consecteturadipiscing elit. Scelerisque amet odio velit,  auctor nam elit nulla.</p>
                    <ul class="social-links">
                        <li><a href="#" target="_blank"><i class="ri-facebook-fill"></i></a></li>
                        <li><a href="#" target="_blank"><i class="ri-twitter-fill"></i></a></li>
                        <li><a href="#" target="_blank"><i class="ri-linkedin-fill"></i></a></li>
                        <li><a href="#" target="_blank"><i class="ri-messenger-fill"></i></a></li>
                        <li><a href="#" target="_blank"><i class="ri-github-fill"></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget pl-2">
                    <h3>Company</h3>
                    <ul class="links-list">
                        <li><a routerLink="/about-simple">About Us</a></li>
                        <li><a routerLink="/features-2">Core Services</a></li>
                        <li><a routerLink="/privacy-policy">Refund Policy</a></li>
                        <li><a routerLink="/faq">FAQ's</a></li>
                        <li><a routerLink="/feedback">Reviews</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Support</h3>
                    <ul class="links-list">
                        <li><a routerLink="/services">Services</a></li>
                        <li><a routerLink="/contact">Support</a></li>
                        <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                        <li><a routerLink="/faq">FAQ's</a></li>
                        <li><a routerLink="/contact">Contact</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Useful Links</h3>
                    <ul class="links-list">
                        <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                        <li><a routerLink="/privacy-policy">Return Policy</a></li>
                        <li><a routerLink="/terms-conditions">Terms & Conditions</a></li>
                        <li><a routerLink="/how-it-works">How It Works?</a></li>
                        <li><a routerLink="/contact">Contact Us</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Newsletter</h3>
                    <p>Best solution for your it startup business, consecteturadipiscing elit.</p>
                    <form class="newsletter-form">
                        <input type="text" class="input-newsletter" placeholder="Your Email" name="EMAIL" required autocomplete="off">
                        <button type="submit"><i class="ri-send-plane-2-line"></i></button>
                    </form>
                </div>
            </div>
        </div>
        <div class="copyright-area">
            <p>© Apzel is Proudly Owned by <a href="https://envytheme.com/" target="_blank">EnvyTheme</a></p>
        </div>
    </div>
    <div class="footer-white-shape">
        <img src="assets/img/more-home/footer/footer-shape.png" alt="image">
    </div>
</div>