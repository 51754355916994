<div class="app-video-area pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="app-intro-video-box">
                    <img src="../../../../../assets/img/products/nvestor-funding-nashville-conference.png" alt="video-img">
                    <button class="video-btn popup-video" (click)="openPopup()"><i class="ri-play-line"></i></button>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="app-intro-video-content">
                    <span class="sub-title">NASHVILLE, TN</span>
                    <h2>Nvestor Funding At The Real Estate Investing Mastermind</h2>
                    <p>Watch Steve Afra the Managing Partner of Nvestor Funding, a dynamic real estate entrepreneur and mentor who has helped countless people reach their goals in the real estate investing. Steve has personally originated, financed and sold over $10 Billion in loans. With his down-to-earth approach and proven strategies for success, Steve knows what it takes to make it big in the real estate investing industry.</p>
                    <a routerLink="/exit-strategy" class="default-btn">Get A Fast Free Quote</a>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Video Popup -->
<div class="video-popup" *ngIf="isOpen">
    <div class="popup-inner">
        <iframe src="https://www.youtube.com/embed/zR--B3gZmbg?si=6sPnnhVyC_JM4VdO" title="Nvestor Funding Steve Afra" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <button (click)="closePopup()" type="button" class="close-btn">
            <i class="ri-close-line"></i>
        </button>
    </div>
</div>