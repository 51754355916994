<div class="app-progress-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="app-progress-image text-center">
                    <img src="../../../../assets/img/team/nvestor-funding-jacalyn-shirley.png" alt="app-img">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="app-progress-content">
                    <span class="sub-title">Welcome to Nvestor Funding</span>
                    <h2>Our Mission</h2>
                    <p>At Nvestor Funding, we are more than just lenders; we are your partners in success. Our mission
                        is to fuel your real estate investment journey with swift, reliable, and affordable financing
                        solutions. Whether you're eyeing a property in the bustling streets of California, the vibrant
                        communities of Tennessee, Florida's scenic coastlines, Texas's expansive landscapes,
                        Washington's booming hubs, or Georgia's charming locales, we're here to make your vision a
                        reality.</p>
                    <a routerLink="/exit-strategy" class="default-btn">Get A Fast Free Quote</a>
                </div>
            </div>
        </div>
    </div>
</div>