<div class="footer-area">
    <div class="container">
        <div class="footer-content">
            <a routerLink="/" class="logo">
                <img src="assets/img/logo2.png" alt="logo">
            </a>
            <ul class="social-links">
                <li><a href="#" target="_blank"><i class="ri-facebook-fill"></i></a></li>
                <li><a href="#" target="_blank"><i class="ri-twitter-fill"></i></a></li>
                <li><a href="#" target="_blank"><i class="ri-linkedin-fill"></i></a></li>
                <li><a href="#" target="_blank"><i class="ri-messenger-fill"></i></a></li>
                <li><a href="#" target="_blank"><i class="ri-github-fill"></i></a></li>
            </ul>
            <ul class="navbar-nav">
                <li class="nav-item"><a routerLink="/services" class="nav-link">Services</a></li>
                <li class="nav-item"><a routerLink="/contact" class="nav-link">Support</a></li>
                <li class="nav-item"><a routerLink="/privacy-policy" class="nav-link">Privacy Policy</a></li>
                <li class="nav-item"><a routerLink="/faq" class="nav-link">FAQ's</a></li>
                <li class="nav-item"><a routerLink="/contact" class="nav-link">Contact</a></li>
            </ul>
            <p class="copyright">© Apzel is Proudly Owned by <a href="https://envytheme.com/" target="_blank">EnvyTheme</a></p>
        </div>
    </div>
</div>