import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from "@angular/common";
import { HubSpotService } from 'src/app/Services/hub-spot.service';

@Component({
  selector: 'app-question-apply-now',
  templateUrl: './question-apply-now.component.html',
  styleUrls: ['./question-apply-now.component.scss']
})
export class QuestionApplyNowComponent {
  public Type:any = [
    {
        type: 'Fix&Flip',
        value: 'Fix&Flip'
    },
    {
        type: 'Rental',
        value: 'Rental'
    },
    {
      type: 'Bridge',
      value: 'Bridge'
    },
    {
      type: 'Construction',
      value: 'Construction'
    },
    {
        type: 'Not Sure Yet',
        value: 'Not Sure Yet'
    }
  ];

  constructor(public hubspot:HubSpotService, private scroller: ViewportScroller) { }

  ngOnInit(): void {
  }

  public GetLoanType(value :any) {
    this.hubspot.QuestionLoanType = value;
    console.log('Loan Type:', this.hubspot.QuestionLoanType);
  }
}
