import { Component } from '@angular/core';

@Component({
  selector: 'app-december-investing-in-florida',
  templateUrl: './december-investing-in-florida.component.html',
  styleUrls: ['./december-investing-in-florida.component.scss']
})
export class DecemberInvestingInFloridaComponent {

}
