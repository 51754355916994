<app-navbar-style-two></app-navbar-style-two>

<app-homeseven-banner></app-homeseven-banner>

<app-homeseven-features></app-homeseven-features>

<app-homeseven-about></app-homeseven-about>

<app-features-style-six></app-features-style-six>

<app-progress-style-four></app-progress-style-four>

<app-screenshots-style-seven></app-screenshots-style-seven>

<app-trusted-features></app-trusted-features>

<app-video></app-video>

<app-pricing-style-six></app-pricing-style-six>

<app-download-app-style-four></app-download-app-style-four>

<app-get-started></app-get-started>

<app-client-reviews></app-client-reviews>

<app-footer-style-four></app-footer-style-four>