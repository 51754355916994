<div class="partner-slides">
    <owl-carousel-o [options]="partnerSlides">
        <ng-template carouselSlide>
            <div class="partner-item">
                <a href="#" class="d-block">
                    <img src="../../../../assets/img/partner/abc.png" alt="image">
                </a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="partner-item">
                <a href="#" class="d-block">
                    <img src="../../../../assets/img/partner/ap.png" alt="image">
                </a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="partner-item">
                <a href="#" class="d-block">
                    <img src="../../../../assets/img/partner/boston-herald.png" alt="image">
                </a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="partner-item">
                <a href="#" class="d-block">
                    <img src="../../../../assets/img/partner/cbs.png" alt="image">
                </a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="partner-item">
                <a href="#" class="d-block">
                    <img src="../../../../assets/img/partner/the-cw.png" alt="image">
                </a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="partner-item">
                <a href="#" class="d-block">
                    <img src="../../../../assets/img/partner/the-miami-herald.png" alt="image">
                </a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="partner-item">
                <a href="#" class="d-block">
                    <img src="../../../../assets/img/partner/daily-herald.png" alt="image">
                </a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="partner-item">
                <a href="#" class="d-block">
                    <img src="../../../../assets/img/partner/nbc.png" alt="image">
                </a>
            </div>
        </ng-template>
    </owl-carousel-o>
</div>