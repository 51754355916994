<div
    class="navbar-area navbar-style-two"
    [ngClass]="{'sticky': isSticky}"
>
    <div class="container">
        <nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied">
            <a class="navbar-brand" routerLink="/"><img style="width: 50%;" src="assets/img/logo/nvestor-funding-logo.png" alt="logo"></a>
            <button class="navbar-toggler" type="button" (click)="toggleClass()">
                <span class="burger-menu">
                    <span class="top-bar"></span>
                    <span class="middle-bar"></span>
                    <span class="bottom-bar"></span>
                </span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav">
                    <li class="nav-item"><a href="javascript:void(0)" class="dropdown-toggle nav-link">Loan Programs</a>
                        <ul class="dropdown-menu">
                            <li class="nav-item"><a routerLink="/fix-and-flip-loans" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Fix and Flip Loans</a></li>
                            <li class="nav-item"><a routerLink="/long-term-rental-loans" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Long Term Rental Loans</a></li>
                            <li class="nav-item"><a routerLink="/short-term-bridge-loans" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Short Term Bridge Loans</a></li>
                            <li class="nav-item"><a routerLink="/ground-up-construction-loans" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Ground Up Construction Loans</a></li>
                        </ul>
                    </li>
                    <li class="nav-item"><a routerLink="/blog" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog</a></li>
                    <li class=nav-item><a href="https://forms.monday.com/forms/72889ccf6e9c10821cdb95315056de90?r=use1" class="nav-link">Draw Requests</a></li>
                    <li class="nav-item"><a routerLink="/about-us" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About Us</a></li>
                    <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact Us</a></li>
                </ul>
                <div class="others-option">
                    <a routerLink="/exit-strategy" class="default-btn">Get A Fast Free Quote</a>
                </div>
            </div>
        </nav>
    </div>
</div>