<div class="container">
    <div class="app-download-inner bg-gray">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="app-download-content">
                    <span class="sub-title">DOWNLOAD APP</span>
                    <h2>Let's Get Your Free Copy From Apple and Play Store</h2>
                    <p>Instant free download from store Cloud based storage for your data backup just log in with your mail account from play store and using whatever you want for your business purpose orem ipsum dummy text.</p>
                    <div class="btn-box">
                        <a href="#" class="playstore-btn" target="_blank">
                            <img src="assets/img/play-store.png" alt="image">
                            Get It On
                            <span>Google Play</span>
                        </a>
                        <a href="#" class="applestore-btn" target="_blank">
                            <img src="assets/img/apple-store.png" alt="image">
                            Download on the
                            <span>Apple Store</span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="app-download-image">
                    <img src="assets/img/app/app-img4.png" alt="app-img">
                </div>
            </div>
        </div>
    </div>
</div>