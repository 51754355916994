<div class="app-screenshots-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">RECENTLY FUNDED</span>
            <h2>Funding Investors Nationwide</h2>
        </div>
        <div class="app-screenshots-slides">
            <owl-carousel-o [options]="screenshotsSlides">
                <ng-template carouselSlide>
                    <div class="single-screenshot-card">
                        <img src="../../../../assets/img/just-funded/nvestor-funding-just-funded-1.png" alt="Nvestor Funding Just Funded">
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-screenshot-card">
                        <img src="../../../../assets/img/just-funded/nvestor-funding-just-funded-2.png" alt="Nvestor Funding Just Funded">
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-screenshot-card">
                        <img src="../../../../assets/img/just-funded/nvestor-funding-just-funded-3.png" alt="Nvestor Funding Just Funded">
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-screenshot-card">
                        <img src="../../../../assets/img/just-funded/nvestor-funding-just-funded-4.png" alt="Nvestor Funding Just Funded">
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</div>