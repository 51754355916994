<app-navbar-style-two></app-navbar-style-two>

<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="blog-details-desc">
                    <div class="article-image">
                        <img src="../../../../../assets/img/blog/Student-Housing-The-Next-Big-Thing-in-CRE.jpg"
                            alt="blog-details">
                    </div>
                    <div class="article-content">
                        <div class="entry-meta">
                            <ul>
                                <li><i class="ri-calendar-2-line"></i>July 14, 2023</li>
                            </ul>
                        </div>
                        <h1>Student Housing: The Next Big Thing in CRE</h1>
                        <p>
                            While office buildings lie empty and multifamily apartment rentals fall, housing in some
                            college markets may be the commercial real estate industry's shining star.
                        </p>
                        <p>
                            According to RealPage, in May 2023, the rent for student housing increased by 9 percent
                            annually, while the rent for multi-family dwellings increased by only 2.3 percent.
                        </p>
                        <p>
                            Despite the fact that 2022 saw abnormally high levels of pent-up demand after the pandemic
                            left campuses desolate, investors like Blackstone expect ongoing growth after purchasing
                            American Campus Communities for $12.8 billion in 2018.
                        </p>
                        <p>
                            The Journal reported comments from Blackstone's co-head of America's acquisitions, who
                            argued that despite economic ups and downs, demand for housing for college students remains
                            relatively stable.
                        </p>
                        <p>
                            However, some industry experts claim that not all college rental markets are growing at the
                            same rates.
                        </p>
                        <p>
                            After the pandemic, there is less available accommodation for the expanding number of
                            students attending colleges and institutions, many of which participate in lucrative
                            sporting conferences and have well-regarded research programs. Sarah Lawrence College and
                            the University of San Diego are just two examples of institutions that have been reported by
                            The Real Deal to temporarily house their students in hotels due to a lack of adequate
                            long-term housing.
                        </p>
                        <p>
                            High borrowing rates pose a threat to new development and the resulting supply across all of
                            commercial real estate, including this sector. The multifamily sector took a hit this year,
                            with sales of apartments falling to a 14-year low due to factors such as flattening rents,
                            economic uncertainty, and increased borrowing costs.
                        </p>
                        <p>
                            Elisa Wilson, of JLL, told the Journal that the market for student housing after the
                            pandemic is a "tale of two markets," but the company's data show that overall student
                            housing is 70% preleased for Fall 2023, which is on par with May 2019 levels.
                        </p>     
                    </div>
                </div>
                <a routerLink="/exit-strategy" class="default-btn mt-2 mb-5">Get A Free Fast Quote</a>
            </div>
            <div class="col-lg-4 col-md-12">
                <aside class="widget-area">
                    <app-blog-sidebar></app-blog-sidebar>
                </aside>
            </div>
        </div>
    </div>
</div>

<app-footer-style-five></app-footer-style-five>