import { Component } from '@angular/core';

@Component({
  selector: 'app-main-why-us',
  templateUrl: './main-why-us.component.html',
  styleUrls: ['./main-why-us.component.scss']
})
export class MainWhyUsComponent {

}
