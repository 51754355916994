<div class="video-area">
    <div class="container">
        <div class="video-box">
            <img src="assets/img/video/video-img2.jpg" alt="video-image">
            <button class="video-btn popup-video" (click)="openPopup()"><i class="ri-play-line"></i></button>
            <div class="shape">
                <img class="shape1" src="assets/img/shape/shape1.png" alt="shape1">
                <img class="shape2" src="assets/img/shape/shape2.png" alt="shape2">
            </div>
        </div>
    </div>
</div>

<!-- Video Popup -->
<div class="video-popup" *ngIf="isOpen">
    <div class="popup-inner">
        <iframe src="https://www.youtube.com/embed/XNQk1qt__Vw" title="Setup and Local Installation - React Nextjs Online Yoga Coaching &amp; Training Courses System" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <button (click)="closePopup()" type="button" class="close-btn">
            <i class="ri-close-line"></i>
        </button>
    </div>
</div>