<div class="container">
    <div class="section-title">
        <span class="sub-title">CLIENT REVIEWS</span>
        <h2>What Our Clients Say About Us</h2>
    </div>
    <div class="feedback-swiper-slides">
        <owl-carousel-o [options]="swiperFeedbackSlides">
            <ng-template carouselSlide>
                <div class="single-feedback-item">
                    <div class="client-info">
                        <div class="title">
                            <h3>Troy S.</h3>
                            <span>Mortgage Broker</span>
                        </div>
                    </div>
                    <p>Nvestor funding is the REAL DEAL! They provide cutting edge products accompanied by even better
                        service. If you are a mortgage broker looking to find a place for your business purpose loan,
                        you MUST give them a try. The entire team is very knowledgeable and they know how to get deals
                        done quickly.</p>
                    <div class="rating">
                        <h5>Ratings</h5>
                        <div>
                            <i class="ri-star-fill"></i>
                            <i class="ri-star-fill"></i>
                            <i class="ri-star-fill"></i>
                            <i class="ri-star-fill"></i>
                            <i class="ri-star-fill"></i>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="single-feedback-item">
                    <div class="client-info">
                        <div class="title">
                            <h3>Bruce P.</h3>
                            <span>Real Estate Investor</span>
                        </div>
                    </div>
                    <p>I worked with Troy and the Nvestor Funding team on a previous rental property of mine. They were
                        extremely helpful throughout the whole process and were able to get my loan closed very fast. I
                        plan to use them on another upcoming rental I plan to pick up in the next few months.</p>
                    <div class="rating">
                        <h5>Ratings</h5>
                        <div>
                            <i class="ri-star-fill"></i>
                            <i class="ri-star-fill"></i>
                            <i class="ri-star-fill"></i>
                            <i class="ri-star-fill"></i>
                            <i class="ri-star-fill"></i>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="single-feedback-item">
                    <div class="client-info">
                        <div class="title">
                            <h3>Cory B.</h3>
                            <span>Real Estate Developer</span>
                        </div>
                    </div>
                    <p>The most painless loan process I have been through. In fact I would say it was pleasant and fast
                        moving. The whole team was very responsive and communicated so so well. They've got my paper
                        from here on out. Thanks Dana, Nick and the rest of the team behind the scenes!</p>
                    <div class="rating">
                        <h5>Ratings</h5>
                        <div>
                            <i class="ri-star-fill"></i>
                            <i class="ri-star-fill"></i>
                            <i class="ri-star-fill"></i>
                            <i class="ri-star-fill"></i>
                            <i class="ri-star-fill"></i>
                        </div>
                    </div>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
</div>