import { Component } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-hard-money-loans-tennessee-page',
  templateUrl: './hard-money-loans-tennessee-page.component.html',
  styleUrls: ['./hard-money-loans-tennessee-page.component.scss']
})
export class HardMoneyLoansTennesseePageComponent {
  constructor(public meta: Meta) {
    this.meta.addTag({
      name: 'description',
      content: `Get a hard money loan in Tennessee from Nvestor Funding. 
                We offer fast funding for fix & flip projects, 
                investment properties, and more. Apply online now!`
    });
  }
}
