import { Component } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-hard-money-loans-arizona-page',
  templateUrl: './hard-money-loans-arizona-page.component.html',
  styleUrls: ['./hard-money-loans-arizona-page.component.scss']
})
export class HardMoneyLoansArizonaPageComponent {
  constructor(public meta: Meta) {
    this.meta.addTag({
      name: 'description',
      content: `Nvestor Funding is a leading hard money lender in Arizona, offering competitive rates and flexible terms for fix & flip, buy & hold, and new construction projects. Apply online!`
    });
  }

}
