<app-navbar-style-two></app-navbar-style-two>

<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Terms Of Use</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Terms of Use</li>
            </ul>
        </div>
    </div>
    <div class="divider"></div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
    <div class="banner-shape1"><img src="assets/img/shape/shape9.png" alt="image"></div>
</div>

<div class="terms-conditions-area ptb-100">
    <div class="container">
        <div class="terms-conditions-content">
            <h5>Binding Effect</h5>
            <p>This is a binding agreement. By using this Internet site (the “Website” or “Site”) or any services
                provided in connection with the Site (the “Service”), you agree to abide by these Terms of Use, as they
                may be amended by Nvestor Funding Inc (“Company”) from time to time in its sole discretion. Nvestor
                Funding Inc will post a notice on the Site any time these Terms of Use have been changed or otherwise
                updated. It is your responsibility to review these Terms of Use periodically, and if at any time you
                find these Terms of Use unacceptable, you must immediately leave the Site and cease all use of the
                Service and the Site. YOU AGREE THAT BY USING THE SERVICE YOU REPRESENT THAT YOU ARE AT LEAST 18 YEARS
                OLD AND THAT YOU ARE LEGALLY ABLE TO ENTER INTO THIS AGREEMENT.
            </p>
            <h5>Privacy Policy</h5>
            <p>
                Nvestor Funding Inc respects your privacy and permits you to control the treatment of your personal
                information. A complete statement of Nvestor Funding Inc's current <a routerLink="/privacy-policy">privacy policy</a> can be found by
                <a routerLink="/privacy-policy">clicking here</a>. Nvestor Funding Inc's <a routerLink="/privacy-policy">privacy policy</a> is expressly incorporated into this Agreement by
                this reference. When you are required to open an account to use or access the Site or Service, you must
                complete the registration process by providing the complete and accurate information requested on the
                registration form. You will also be asked to provide a user name and password. You are entirely
                responsible for maintaining the confidentiality of your password. You may not use the account, username,
                or password of someone else at any time. You agree to notify Nvestor Funding Inc immediately on any
                unauthorized use of your account, username, or password. Nvestor Funding Inc shall not be liable for any
                loss that you incur as a result of someone else using your password, either with or without your
                knowledge. You may be held liable for any losses incurred by Nvestor Funding Inc, its affiliates,
                officers, directors, employees, consultants, agents, and representatives due to someone else's use of
                your account or password.
            </p>
            <h5>Use of Software</h5>
            <p>
                Nvestor Funding Inc may make certain software available to you from the Site. If you download software
                from the Site, the software, including all files and images contained in or generated by the software,
                and accompanying data (collectively, “Software”) are deemed to be licensed to you by Nvestor Funding
                Inc, for your personal, noncommercial, home use only. Nvestor Funding Inc does not transfer either the
                title or the intellectual property rights to the Software, and Nvestor Funding Inc retains full and
                complete title to the Software as well as all intellectual property rights therein. You may not sell,
                redistribute, or reproduce the Software, nor may you decompile, reverse-engineer, disassemble, or
                otherwise convert the Software to a human-perceivable form. All trademarks and logos are owned by
                Nvestor Funding Inc or its licensors/business partners and you may not copy or use them in any manner.
            </p>
            <h5>User Content</h5>
            <p>You grant Nvestor Funding Inc a license to use the materials you post to the Site or Service. By posting,
                downloading, displaying, performing, transmitting, or otherwise distributing information or other
                content (“User Content”) to the Site or Service, you are granting Nvestor Funding Inc, its affiliates,
                officers, directors, employees, consultants, agents, and representatives a license to use User Content
                in connection with the operation of the business of Nvestor Funding Inc, its affiliates, officers,
                directors, employees, consultants, agents, and representatives, including without limitation, a right to
                copy, distribute, transmit, publicly display, publicly perform, reproduce, edit, translate, and reformat
                User Content. You will not be compensated for any User Content. By posting User Content on the Site or
                Service, you warrant and represent that you own the rights to the User Content or are otherwise
                authorized to post, distribute, display, perform, transmit, or otherwise distribute User Content.
            </p>
            <h5>Website Content</h5>
            <p>
                This website may provide general information about our products and services. Your eligibility for
                particular products and services is subject to our final determination, restrictions, and acceptance.
                You agree to accept our final determination and conditions. We may discontinue or make changes to the
                information, products, licenses, or services described on this website at any time. Any dated
                information is published as of its publication date only. We do not undertake any obligation or
                responsibility to update or amend any such information. We reserve the right to terminate any or all
                offerings without prior notice. Furthermore, by offering information, products, or services via this
                website, no solicitation is made by us to any person to use such information, products, or services in
                jurisdictions where the provision of information, products, or services is prohibited by law.
            </p>
            <br>
            <p>This website may contain links to third-party websites, such as social media websites, which we do not
                own or control, but which are provided for your convenience. If you visit a link to another website, you
                do so at your own risk subject to the terms and conditions established by the operator of that website.
                The fact that we provide a link to a website does not mean we endorse, authorize, or sponsor that
                third-party website, or that we are affiliated with the third-party website’s owners or sponsors. We
                reserve the right to terminate a link to a third-party website at any time.
            </p>
            <br>
            <p>
                Although we try to provide accurate and timely information on this website, there may be inadvertent,
                technical, or factual inaccuracies and typographical errors. For these reasons we cannot warrant the
                accuracy, completeness, or timeliness of the information, text, graphics, links, or other items on this
                website.
            </p>
            <br>
            <p>Compliance with Intellectual Property Laws</p>
            <br>
            <p>
                When accessing the Site or using the Service, you agree to obey the law and to respect the intellectual
                property rights of others. Your use of the Service and the Site is at all times governed by and subject
                to laws regarding copyright ownership and use of intellectual property. You agree not to upload,
                download, display, perform, transmit, or otherwise distribute any information or content (collectively,
                “Content”) in violation of any third party's copyrights, trademarks, or other intellectual property or
                proprietary rights. You agree to abide by laws regarding copyright ownership and use of intellectual
                property, and you shall be solely responsible for any violations of any relevant laws and for any
                infringements of third-party rights caused by any Content you provide or transmit, or that is provided
                or transmitted using your user identifying information. The burden of proving that any Content you
                provide or transmit does not violate any laws or third-party rights rests solely with you.
            </p>
            <br>
            <p>
                This website's content, including but not limited to all sounds, images, icons, text, software, logos,
                expressions, and ideas, is copyrighted and protected by U.S. and worldwide copyright laws and treaty
                provisions. In addition, this website's content is protected by patent and trademark laws, the laws of
                privacy and publicity, and the various communication regulations and statutes. You are not authorized to
                post on or transmit to or from this website any unlawful, threatening, libelous, defamatory, obscene,
                scandalous, inflammatory, pornographic, immoral, or profane material, or any other content that could
                give rise to any civil or criminal liability under the law.
            </p>
            <h5>Inappropriate Content</h5>
            <p>
                You shall not make the following types of Content available. You agree not to upload, download, display,
                perform, transmit, or otherwise distribute any Content that (a) is libelous, defamatory, obscene,
                pornographic, abusive, or threatening; (b) advocates or encourages conduct that could constitute a
                criminal offense, give rise to civil liability, or otherwise violate any applicable local, state,
                national, or foreign law or regulation; or (c) advertises or otherwise solicits funds or is a
                solicitation for goods or services. Nvestor Funding Inc reserves the right to terminate your use of the
                Service, and, if applicable, to delete any such material from its servers. Nvestor Funding Inc intends
                to cooperate fully with any law enforcement officials or agencies in the investigation of any violation
                of these Terms of Use or of any applicable laws.
            </p>
            <h5>Copyright Infringement</h5>
            <p>Nvestor Funding Inc has in place certain legally mandated procedures regarding allegations of copyright
                infringement occurring on the Site or with the Service. Nvestor Funding Inc has adopted a policy that
                provides for the immediate suspension and/or termination of any user who is found to have infringed on
                the rights of Nvestor Funding Inc or of a third party, or otherwise violated any intellectual property
                laws or regulations. Nvestor Funding Inc's policy is to investigate any allegations of copyright
                infringement brought to its attention. If you have evidence, know, or have a good faith belief that your
                rights or the rights of a third party have been violated and you want Nvestor Funding Inc to delete,
                edit, or disable the material in question, you must provide Nvestor Funding Inc with all of the
                following information: (a) a physical or electronic signature of a person authorized to act on behalf of
                the owner of the exclusive right that is allegedly infringed; (b) identification of the copyrighted work
                claimed to have been infringed, or, if multiple copyrighted works are covered by a single notification,
                a representative list of such works; (c) identification of the material that is claimed to be infringed
                or to be the subject of infringing activity and that is to be removed or access to which is to be
                disabled, and information reasonably sufficient to permit Nvestor Funding Inc to locate the material;
                (d) information reasonably sufficient to permit Nvestor Funding Inc to contact you, such as an address,
                telephone number, and if available, an electronic mail address at which you may be contacted; (e) a
                statement that you have a good faith belief that use of the material in the manner complained of is not
                authorized by the copyright owner, its agent, or the law; and (f) a statement that the information in
                the notification is accurate, and under penalty of perjury, that you are authorized to act on behalf of
                the owner of an exclusive right that is allegedly infringed. For this notification to be effective, you
                must provide it to Nvestor Funding Inc's designated agent at:
            </p>
            <br>
            <p>Ras Abdelrasul, President Nvestor Funding Inc 40 W. Baseline Road #206, Tempe AZ 85283 (877)231-3111
                 {{ this.email }} </p>
            <h5>Accessing the Website</h5>
            <p>You agree and acknowledge that you have the sole responsibility and liability for your use of this
                website and for providing or obtaining, and for maintaining, all of the hardware, software, electrical
                power, telecommunications, Internet services, and other products or services necessary or desirable for
                you to access and use this website.
            </p>
            <br>
            <p>From time to time, due to the performance of maintenance, malfunctions or failures of software,
                equipment, or telecommunications devices, unusual transaction volume, or similar reasons, the website
                may not be available for your use. We minimize the periods of time during which website is unavailable.
                You agree that we shall not be responsible for any loss, damages, costs, or expenses which you may
                suffer or incur, directly or indirectly, as a result of the unavailability of the website, regardless of
                whether it could be shown that we could have prevented or reduced the duration of such unavailability by
                taking any action within our reasonable control. We shall also not be responsible for any loss, damages,
                costs, or expenses which you may suffer or incur, directly or indirectly, as a result of your inability
                to access the website caused directly or indirectly, in whole or in part, by your computer or mobile
                device, your inability to establish a connection to the Internet, your Internet Service Provider
                (“ISP”), or capacity or other limitations or constraints of the Internet.
            </p>
            <br>
            <p>We may at any time, with or without cause, and without prior notice to you deny you access to the
                website. We may terminate these Terms of Use and your access to the website in whole or in part at any
                time without prior notice to you. In the event we terminate this Terms of Use, any applications you have
                submitted will continue to be evaluated, and any existing transactions you have entered into with us
                shall remain in effect.
            </p>
            <br>
            <p>The website is not intended for use by persons outside of the state in which Nvestor Funding Inc has been
                approved to offer mortgage loan products and services or in states where we have not received website
                approval where such website approval is required. We may restrict your access to the website during
                times you are in a country for which use of the website would be prohibited. You are responsible for
                compliance with all local laws.
            </p>
            <br>
            <p>
                To protect the security of your information, we may require you to authenticate your identity (i.e.,
                prove that you are who you say you are) to conduct certain transactions on this website. It is a good
                idea to protect your security by always closing your web browser after leaving the website. If you
                believe that information that you have submitted through the website has been used without your
                permission, you must tell us immediately. Telephoning us is the best way of keeping your possible losses
                to a minimum. Call us at (877)231-3111 to report unauthorized access. If you fail to notify us, you may
                be liable for all unauthorized activity on your account. You can also contact us at (877)231-3111
                regarding technical issues with this website.
            </p>
            <br>
            <h5>SMS Consent</h5>
            <p>Providing your telephone contact information to us means you have consented in writing to receive SMS
                communications (text messages) from us. When consenting to messaging notifications, you authorize
                Nvestor Funding Inc, our assigns, successors or servicing agents to send SMS Notifications (as defined
                below) to any phone number provided to us, our assigns, successors or service agents in connection with
                your account, application, loan, and closing. As used in this text messaging disclosure, “SMS Account
                Notifications” means any SMS (text message) communications from us to you pertaining to your account or
                loan transaction sent to the phone number provided in connection with this transaction, including but
                not limited to application processing status, account information, loan information, information
                requests, document requests, due dates, delinquent accounts, closings and program updates.
            </p>
            <h5>How to Unsubscribe:</h5>
            <p>You may withdraw your consent to receive SMS Account Notifications by replying with “stop, end, cancel,
                unsubscribe, or quit” or by calling us at (877)231-3111 at any time. We may treat your provision of an
                invalid mobile phone number, or the subsequent malfunction of a previously valid mobile phone number, as
                a withdrawal of your consent to receive SMS Account Notifications. Any withdrawal of your consent to use
                SMS Account Notifications will be effective only after we have a reasonable period of time to process
                your withdrawal. To request additional information, contact us by telephone at (877)231-3111. In order
                to access, view, and retain SMS Account Notifications that we make available to you, you must have: (i)
                a SMS-capable mobile phone, (ii) an active mobile phone account with a communication service provider;
                and (iii) sufficient storage capacity on your mobile phone.
            </p>
            <h5>All SMS Account Notifications in electronic format from us to you will be considered “in writing.”</h5>
            <p>There is no service fee for SMS Account Notifications but you are responsible for any and all charges,
                including but not limited to fees associated with text messaging, imposed by your communications service
                provider. Please consult your mobile service carrier's pricing plan to determine the charges for sending
                and receiving text messages. These charges will appear on your phone bill. Message frequency depends on
                account status or settings. We may modify or terminate our text messaging services from time to time,
                for any reason, and without notice, including the right to terminate text messaging with or without
                notice, without liability to you.
            </p>
            <h5>Prohibited Uses</h5>
            <p>Nvestor Funding Inc imposes certain restrictions on your permissible use of the Site and the Service. You
                are prohibited from violating or attempting to violate any security features of the Site or Service,
                including, without limitation, (a) accessing content or data not intended for you, or logging onto a
                server or account that you are not authorized to access; (b) attempting to probe, scan, or test the
                vulnerability of the Service, the Site, or any associated system or network, or to breach security or
                authentication measures without proper authorization; (c) interfering or attempting to interfere with
                service to any user, host, or network, including, without limitation, by means of submitting a virus to
                the Site or Service, overloading, “flooding,” “spamming,” “mail bombing,” or “crashing;” (d) using the
                Site or Service to send unsolicited e-mail, including, without limitation, promotions, or advertisements
                for products or services; (e) forging any TCP/IP packet header or any part of the header information in
                any e-mail or in any posting using the Service; or (f) attempting to modify, reverse-engineer,
                decompile, disassemble, or otherwise reduce or attempt to reduce to a human-perceivable form any of the
                source code used by Nvestor Funding Inc in providing the Site or Service. Any violation of system or
                network security may subject you to civil and/or criminal liability.
            </p>
            <h5>Your Information</h5>
            <p>Please review our <a routerLink="/privacy-policy">privacy policy</a> to fully understand the way we use the information that you provide to
                us, or that we collect through other approved methods. During the process of your mortgage application,
                you may submit certain information to us. We do not claim ownership of the materials you provide
                (including feedback and suggestions), post, upload, input, or submit to nvsetorfunding.com or any of its
                affiliated businesses (collectively “Your Information”). However, by posting, uploading, inputting,
                providing, or submitting Your Information, you are granting us, our affiliated companies, and any
                vendors necessary to the course of your transaction permission to use Your Information to fulfill your
                request for services and for purposes defined in our <a routerLink="/privacy-policy">privacy policy</a>. That permission includes, but is
                not limited to allowing us to copy, distribute, transmit, publicly display (we will never publicly
                display your non-public personal information), reproduce, edit, translate, and reformat Your
                Information. Please note that specific terms and conditions provided on the web page describing a
                particular feature or offer, may supersede this provision.
            </p>
            <h5>Consent to Verifications</h5>
            <p>By providing us with your information you are providing written consent permitting us to contact third
                parties as we deem necessary to provide our services to you.
            </p>
            <h5>Affiliated Sites</h5>
            <p>Nvestor Funding Inc has no control over, and no liability for any third-party websites or materials.
                Nvestor Funding Inc works with a number of partners and affiliates whose Internet sites may be linked
                with the Site. Because neither Nvestor Funding Inc nor the Site has control over the content and
                performance of these partner and affiliate sites, Nvestor Funding Inc makes no guarantees about the
                accuracy, currency, content, or quality of the information provided by such sites, and Nvestor Funding
                Inc assumes no responsibility for unintended, objectionable, inaccurate, misleading, or unlawful content
                that may reside on those sites. Similarly, from time to time in connection with your use of the Site,
                you may have access to content items (including, but not limited to, websites) that are owned by third
                parties. You acknowledge and agree that Company makes no guarantees about, and assumes no responsibility
                for, the accuracy, currency, content, or quality of this third-party content, and that, unless expressly
                provided otherwise, these Terms of Use shall govern your use of any and all third-party content.
            </p>
            <h5>No Warranties</h5>
            <p>Nvestor Funding Inc HEREBY DISCLAIMS ALL WARRANTIES. Nvestor Funding Inc IS MAKING THE SITE AVAILABLE “AS
                IS” WITHOUT WARRANTY OF ANY KIND. YOU ASSUME THE RISK OF ANY AND ALL DAMAGE OR LOSS FROM USE OF, OR
                INABILITY TO USE, THE SITE OR THE SERVICE. TO THE MAXIMUM EXTENT PERMITTED BY LAW, Nvestor Funding Inc
                EXPRESSLY DISCLAIMS ANY AND ALL WARRANTIES, EXPRESS OR IMPLIED, REGARDING THE SITE, INCLUDING, BUT NOT
                LIMITED TO, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR
                NONINFRINGEMENT. Nvestor Funding Inc DOES NOT WARRANT THAT THE SITE OR THE SERVICE WILL MEET YOUR
                REQUIREMENTS OR THAT THE OPERATION OF THE SITE OR THE SERVICE WILL BE UNINTERRUPTED OR ERROR-FREE.
            </p>
            <h5>Limited Liability</h5>
            <p>Nvestor Funding Inc LIABILITY TO YOU IS LIMITED. TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT
                SHALL Nvestor Funding Inc BE LIABLE FOR DAMAGES OF ANY KIND (INCLUDING, BUT NOT LIMITED TO, SPECIAL,
                INCIDENTAL, OR CONSEQUENTIAL DAMAGES, LOST PROFITS, OR LOST DATA, REGARDLESS OF THE FORESEEABILITY OF
                THOSE DAMAGES) ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF THE SITE OR ANY OTHER MATERIALS OR
                SERVICES PROVIDED TO YOU BY Nvestor Funding Inc OR FROM YOUR USE OF, OR INABILITY TO USE, THIS WEBSITE
                OR ANY INFORMATION PROVIDED ON THIS WEBSITE; OR IN CONNECTION WITH ANY FAILURE OF PERFORMANCE, ERROR,
                OMISSION, INTERRUPTION, DEFECT, DELAY IN OPERATION OR TRANSMISSION, COMPUTER VIRUS OR LINE OR SYSTEM
                FAILURE; OR DISCLOSURE OF INFORMATION WHEN REPLYING TO YOU BY E-MAIL OR OTHER ELECTRONIC MEANS OR
                RECEIVING E-MAILS FROM YOU; EVEN IF WE ARE ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, LOSSES, OR
                EXPENSES. Some jurisdictions do not allow the exclusion or limitation of liability for consequential or
                incidental damages. In such jurisdictions, our liability is limited to the greatest extent permitted by
                law. This limitation shall apply regardless of whether the damages arise out of breach of contract,
                tort, or any other legal theory or form of action.
            </p>
            <br>
            <p>WE ASSUME NO RESPONSIBILITY, AND SHALL NOT BE LIABLE FOR, ANY VIRUSES THAT MAY INFECT OR DAMAGE YOUR
                COMPUTER EQUIPMENT OR OTHER PROPERTY AS A RESULT OF YOUR ACCESS TO, USE OF, OR BROWSING OF THIS WEBSITE,
                THE WEB, OR YOUR DOWNLOADING OF ANY MATERIALS, DATA, TEXT, IMAGES, OR MEDIA FROM THIS WEBSITE OR THE WEB
            </p>
            <br>
            <p>WE DO NOT WARRANT OR REPRESENT THAT YOUR USE OF MATERIALS DISPLAYED ON THIS WEBSITE WILL NOT INFRINGE
                RIGHTS OF THIRD PARTIES NOT OWNED OR AFFILIATED WITH US.
            </p>
            <br>
            <h5>Indemnity</h5>
            <p>You agree to indemnify Nvestor Funding Inc for certain of your acts and omissions. You agree to
                indemnify, defend, and hold harmless Nvestor Funding Inc, its affiliates, officers, directors,
                employees, consultants, agents, and representatives from any and all third party claims, losses,
                liability, damages, and/or costs (including reasonable attorney fees and costs) arising from your access
                to or use of the Site, your violation of these Terms of Use, or your infringement, or infringement by
                any other user of your account, of any intellectual property or other right of any person or entity.
                Nvestor Funding Inc will notify you promptly of any such claim, loss, liability, or demand, and will
                provide you with reasonable assistance, at your expense, in defending any such claim, loss, liability,
                damage, or cost. You further agree that you will cooperate fully in the defense of any such claims. We
                reserve the right, at our own expense, to assume the exclusive defense and control of any matter
                otherwise subject to indemnification by you, and you shall not in any event settle any such claim or
                matter without our written consent.
            </p>
            <h5>Other Terms & Conditions</h5>
            <hr>
            <h5>Copyright</h5>
            <p>All contents of Site or Service are: Copyright © 2024 Nvestor Funding Inc, 40 W. Baseline Road #206 Tempe
                AZ 85283. All rights reserved.
            </p>
            <h5>Governing Law</h5>
            <p>These Terms of Use shall be construed in accordance with and governed by the laws of the United States
                and the State of Delaware, without reference to their rules regarding conflicts of law. You hereby
                irrevocably consent to the exclusive jurisdiction of the state or federal courts in Kent County,
                Delawre, USA in all disputes arising out of or related to the use of the Site or Service
            </p>
            <h5>Severability; Waiver</h5>
            <p>If, for whatever reason, a court of competent jurisdiction finds any term or condition in these Terms of
                Use to be unenforceable, all other terms and conditions will remain unaffected and in full force and
                effect. No waiver of any breach of any provision of these Terms of Use shall constitute a waiver of any
                prior, concurrent, or subsequent breach of the same or any other provisions hereof, and no waiver shall
                be effective unless made in writing and signed by an authorized representative of the waiving party.
            </p>
            <h5>No License</h5>
            <p>Nothing contained on the Site should be understood as granting you a license to use any of the
                trademarks, service marks, or logos owned by Nvestor Funding Inc or by any third party.
            </p>
            <h5>Modifications</h5>
            <p>Nvestor Funding Inc may, in its sole discretion and without prior notice, (a) revise these Terms of Use;
                (b) modify the Site and/or the Service; and (c) discontinue the Site and/or Service at any time. Nvestor
                Funding Inc shall post any revision to these Terms of Use to the Site, and the revision shall be
                effective immediately on such posting. You agree to review these Terms of Use and other online policies
                posted on the Site periodically to be aware of any revisions. You agree that, by continuing to use or
                access the Site following notice of any revision, you shall abide by any such revision.
            </p>
            <h5>Acknowledgement. By Using the Service or Accessing the Site, You Acknowledge That You Have Read These
                Terms of Use and Agree to Be Bound by Them
            </h5>
            <p>You agree that we may from time to time amend all or any part of these Terms of Use, including, without
                limitation, establishing, increasing, or decreasing the fees and charges for products and services made
                available through this website and changing the features and functionality to this website. We will
                notify you electronically of any change to these Terms of Use by posting updates here or as otherwise
                required by applicable law. Your use of the website after the effective date of the change shall
                constitute your agreement to be bound by the terms of the change. You should also review these Terms of
                Use periodically for any changes.
            </p>
        </div>
    </div>
</div>

<app-footer-style-five></app-footer-style-five>