import { Component } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-long-term-rental-product-page',
  templateUrl: './long-term-rental-product-page.component.html',
  styleUrls: ['./long-term-rental-product-page.component.scss']
})
export class LongTermRentalProductPageComponent {
  constructor(public meta: Meta) {
    this.meta.addTag({
      name: 'description',
      content: `Get competitive rates and flexible terms on long term rental 
                loans from Nvestor Funding. We finance a 
                variety of property types and loan amounts.`
    });
  }
}
