<app-navbar-style-two></app-navbar-style-two>
<app-homeeight-banner></app-homeeight-banner>
<app-funfacts-style-three></app-funfacts-style-three>
<app-main-fix-and-flip-product></app-main-fix-and-flip-product>
<app-main-long-term-rental-product></app-main-long-term-rental-product>
<app-main-short-term-bridge-product></app-main-short-term-bridge-product>
<app-main-ground-up-construction-product></app-main-ground-up-construction-product>
<!-- <app-main-why-us></app-main-why-us> -->
<app-main-how-it-works></app-main-how-it-works>
<div class="partner-area pb-100">
    <div class="container">
        <div class="partner-title">
            As Seen On:
        </div>
        <app-partner></app-partner>
    </div>
</div>

<app-homeeight-intro></app-homeeight-intro>

<app-main-just-funded></app-main-just-funded>

<div class="feedback-wrap-area ptb-100">
    <app-testimonials></app-testimonials>
</div>

<app-homeeight-blog></app-homeeight-blog>
<app-footer-style-five></app-footer-style-five>